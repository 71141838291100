import { Divider } from "@mui/material";
import React from "react";
import { BiPhone } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import FilterIcon from "@mui/icons-material/Filter";
// import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
// import HomeIcon from '@mui/icons-material/Home';
import EqualizerIcon from "@mui/icons-material/Equalizer";
// import AccountBoxIcon from '@mui/icons-material/AccountBox';
import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import ViewListIcon from "@mui/icons-material/ViewList";
import FavoriteSharpIcon from "@mui/icons-material/FavoriteSharp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SaveSharpIcon from "@mui/icons-material/SaveSharp";
import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import PeopleIcon from "@mui/icons-material/People";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import baseURL from "../../config";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";
import { useEffect } from "react";
import { addUserProfileData } from "../../ReduxStore/Slices/UserProfileData";
import axios from "axios";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { RxHome } from "react-icons/rx";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
const MobileSidebar = () => {
  const profileData = useSelector((state) => state.userProfileDataSlice[0]);
  const [data, setData] = useState();
  const cookies = new Cookies();
  const jwt_token = cookies.get("jwt_authorization");
  const decode = jwtDecode(jwt_token);
  const UserRole =
    decode["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
  const dispatch = useDispatch();
  const fetchCurrentUserData = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${jwt_token}`,
      };
      const response = await axios.get(
        `${baseURL}/api/User-Data/GetCurrentUser`,
        { headers }
      );
      setData(response?.data?.data);
      dispatch(addUserProfileData(response?.data?.data));
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (jwt_token) {
      if (!profileData) {
        fetchCurrentUserData();
      } else {
        setData(profileData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt_token, profileData, dispatch]);

  const formatPhoneNumber = (inputNumber) => {
    const cleaned = ("" + inputNumber).replace(/\D/g, "").slice(0, 11);
    const match = cleaned.match(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})$/);
    let formattedNumber = "";
    if (match[1]) {
      formattedNumber += `+${match[1]}`;
    }
    if (match[2]) {
      formattedNumber += ` (${match[2]}`;
    }
    if (match[3]) {
      formattedNumber += `) ${match[3]}`;
    }
    if (match[4]) {
      formattedNumber += `-${match[4]}`;
    }
    return formattedNumber;
  };

  return (
    <>
      <div className="mobile-sidebar-container">
        <div className="d-flex align-center mt-2 sidemargin ps-2">
          <img
            style={{ width: "65px", height: "65px" }}
            className="border rounded-circle"
            src={
              data?.imageUrl
                ? data.imageUrl.startsWith("https://lh3.googleusercontent.com")
                  ? data.imageUrl // Google image URL
                  : `${baseURL}/${data.imageUrl}` // Backend image URL
                : require("../../images/ProfileAvatar.jpg") // Default image if no URL is provided
            }
            alt="User Profile"
            loading="lazy"
          />

          <div div className="profiledesc  ps-2 pt-2">
            <h6 className="text-primary fw-bold fs-6">Hi, {data?.firstName}</h6>
            <p className="font13">{data?.roles[0]}</p>
          </div>
          <NavLink to="/" className="ms-auto">
            <Button>
              <RxHome /> Home
            </Button>
          </NavLink>
        </div>

        <Divider className="bg-black mt-4 " />

        <div className="ps-4 mt-3 flex-column justify-content-center ">
          {data?.phoneNumber && (
            <div className="d-flex text-center ">
              <BiPhone className="mt-1" />
              <p className="ps-2 font13">
                {formatPhoneNumber(data?.phoneNumber)}
              </p>
            </div>
          )}
          <div className="d-flex text-center ">
            <AiOutlineMail className="mt-1" />
            <p className="ps-2 font13">{data?.email}</p>
          </div>
        </div>
        <Divider className="bg-black mt-2 " />

        <div className="ps-4">
          <ul className="ps-0 mt-3 fs-6 font-Montserrat">
            <li className="mt-2">
              <NavLink to="/dashboard/profile" activeClassName="active">
                <AccountCircleSharpIcon className="text-primary" />
                <span className="ps-3">Profile</span>
              </NavLink>
            </li>

            {/* {
                            (UserRole === "Seller" || UserRole === "SuperAdmin" || UserRole === "Staff" || UserRole === "Buyer") ?
                                <li className='mt-2 '>
                                    <NavLink to="/dashboard/Messages" activeclassname="active">
                                        <MoveToInboxIcon className='text-primary' />
                                        <span className='ps-3'>My Messages</span>
                                    </NavLink>
                                </li>
                                : null
                        } */}

            {UserRole === "Seller" ||
            UserRole === "Staff" ||
            UserRole === "Buyer" ||
            UserRole === "Realtor" ? (
              <li className="mt-2">
                <NavLink to="/dashboard/favourite" activeClassName="active">
                  <FavoriteSharpIcon className="text-primary" />
                  <span className="ps-3">Favourite</span>
                </NavLink>
              </li>
            ) : null}

            {/* <li className='mt-2'>
                            <NavLink to="/dashboard/home" activeClassName="active">
                                <HomeIcon className='text-primary' />
                                <span className='ps-3'>Dashboard</span>
                            </NavLink>
                        </li> */}
            {/* {(UserRole === "SuperAdmin" || UserRole === "Staff") ?
                            <li className='mt-2'>
                                <NavLink to="/dashboard/users" activeClassName="active">
                                    <PeopleIcon className='text-primary' />
                                    <span className='ps-3'>User Listings</span>
                                </NavLink>
                            </li>
                            : null
                        } */}
            {UserRole === "SuperAdmin" || UserRole === "Staff" ? (
              <li className="mt-2">
                <NavLink to="/dashboard/users" activeClassName="active">
                  <PeopleIcon className="text-primary" />
                  <span className="ps-3">Users</span>
                </NavLink>
              </li>
            ) : null}
            {UserRole === "Seller" ||
            UserRole === "Staff" ||
            UserRole === "Marketing" ||
            UserRole === "Realtor" ? (
              <li className="mt-2">
                <NavLink
                  to="/dashboard/assignments-listing/Active"
                  activeClassName="active"
                >
                  <ViewListIcon className="text-primary" />
                  <span className="ps-3">Listing & Verification</span>
                </NavLink>
              </li>
            ) : null}

            {(UserRole === "Seller" ||
              UserRole === "Staff" ||
              UserRole === "Realtor") && (
              <li className="mt-2">
                <NavLink
                  to="/dashboard/order-summary/Active"
                  activeClassName="active"
                >
                  <ArticleOutlinedIcon className="text-primary" />
                  <span className="ps-3">My Order</span>
                </NavLink>
              </li>
            )}

            {(UserRole === "Seller" ||
              UserRole === "Staff" ||
              UserRole === "Buyer" ||
              UserRole === "Realtor") && (
              <li className="mt-2">
                <NavLink to="/dashboard/saved-search" activeClassName="active">
                  <SaveSharpIcon className="text-primary" />
                  <span className="ps-3">Saved Searches</span>
                </NavLink>
              </li>
            )}

            {(UserRole === "SuperAdmin" || UserRole === "Staff") && (
              <li className="mt-2">
                <NavLink to="/dashboard/stats" activeClassName="active">
                  <EqualizerIcon className="text-primary" />
                  <span className="ps-3">Statistics</span>
                </NavLink>
              </li>
            )}
            {/* {(UserRole === "SuperAdmin" || UserRole === "Staff") && <li className='mt-2'>
                            <NavLink to="/dashboard/buyer-request" activeClassName="active">
                                <BusinessCenterIcon className='text-primary' />
                                <span className='ps-3'>Buyer Request</span>
                            </NavLink>
                        </li>} */}
            {(UserRole === "SuperAdmin" || UserRole === "Staff") && (
              <li className="mt-2">
                <NavLink
                  to="/dashboard/refined-images"
                  activeClassName="active"
                >
                  <FilterIcon className="text-primary" />
                  <span className="ps-3">Refined Images</span>
                </NavLink>
              </li>
            )}
            {(UserRole === "SuperAdmin" || UserRole === "Staff") && (
              <li className="mt-2">
                <NavLink to="/dashboard/admin-emails" activeClassName="active">
                  <MailOutlineIcon className="text-primary" />
                  <span className="ps-3">Email Inboxes</span>
                </NavLink>
              </li>
            )}

            {UserRole === "SuperAdmin" || UserRole === "Staff" ? (
              <li className="mt-2">
                <NavLink to="/dashboard/highlights" activeClassName="active">
                  <HighlightAltIcon className="text-primary" />
                  <span className="ps-3">Web Highlights</span>
                </NavLink>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    </>
  );
};

export default MobileSidebar;
