import React from "react";
import { TicWithkBorderIcon } from "../../SvgIcons/Icons";
import { NavLink, useNavigate } from "react-router-dom";

const ThanksSubscriber = () => {

const navigate = useNavigate()

  return (
    <div className="coming_soonMain position-relative z-1">
      <div className="comingSoon_container px-4 pt-4 pb-5">
        <div>
          <NavLink to="/">
            <img
              className="comingSoonLogo"
              src={require("../Images/Web_logo.png")} 
              alt="AssignmentSold Logo"
              loading="lazy"
            />
          </NavLink>
        </div>
        <div className="comingSoon_SubcriberInfo comingSoon_thanks">
          <div className="subscriber_role">
            <div
              className="propertyDetail_homeValue_icon"
              style={{
                borderColor: "#ECFDF3",
                background: "var(--Light-green, #D1FADF",
              }}
            >
              <TicWithkBorderIcon />
            </div>
            <h6 className="m-0">Congratulations! You're All Set!</h6>
            <p className="m-0">
              Thank you for joining Canada's largest Off-Market Assignment
              platform. We’re excited to help you succeed!
            </p>
            <button
              className="btn btn-primary w-100 mt-3"
              onClick={() => navigate('/')}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
      <div className="position-absolute bottom-0 w-100" style={{ zIndex: -1 }}>
        <img
          src="/images/ComingSoonFooter.png"
          alt="comingSoon-footer"
          loading="lazy"
          width={"100%"}
        />
      </div>
    </div>
  );
};

export default ThanksSubscriber;
