import React, { useState, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import axios from "axios";
import { getDistance } from "geolib";

mapboxgl.accessToken = `${process.env.REACT_APP_MAPBOX_TOKEN}`;


const PropertyMap = (props) => {
  const { setNearPlacesData, setIsLoading, activeTab, data, hoveredCardId } = props;

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [markers, setMarkers] = useState([]);
  const locationMarker = useRef(null);

  const [center, setCenter] = useState({
    lng: data?.longitude,
    lat: data?.latitude,
  });

  const createIcon = (category, id) => {
    const icon = document.createElement("div");
    icon.className = `custom_map_marker`;
    icon.id = id;
    icon.innerHTML = `<img src="/images/${category}Icon.svg" alt='${category} Icon' />`;

    return icon;
  };

  useEffect(() => {
    if (map.current) return; // Initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [center.lng, center.lat], // Center of the map
      zoom: 12,
    });

    locationMarker.current = new mapboxgl.Marker({ color: "#f8a23e" })
      .setLngLat([center.lng, center.lat])
      .addTo(map.current);

    // Set the z-index of the marker using CSS
    const markerElement = locationMarker.current.getElement();
    markerElement.style.zIndex = "1";
  }, [center]);

  // Function to fetch POIs based on the selected category
  const fetchPOIData = async (category) => {
    // Clear previous markers
    markers.forEach((marker) => marker.remove());

    try {
      // Use Geocoding API to search for places
      const geoResponse = await axios.get(
        `https://api.mapbox.com/search/searchbox/v1/category/${category}`,
        {
          params: {
            access_token: mapboxgl.accessToken,
            language: "en",
            limit: 10,
            proximity: `${center.lng},${center.lat}`,
          },
        }
      );

      const propertyCoordinates = [center.lng, center.lat];
      const nearPlaces = [];
      const newMarkers = [];

      // Loop through each POI and calculate the distance and duration
      const promises = geoResponse.data.features.map(async (feature) => {
        const [lng, lat] = feature.geometry.coordinates;
        const poiCoordinates = [lng, lat];

        // Calculate the walking distance using Turf.js
        const calculatedDistance = getDistance(
          { latitude: center.lat, longitude: center.lng },
          { latitude: lat, longitude: lng }
        );

        // Fetch the walking duration from Mapbox Directions API in parallel
        const directionsResponse = await axios.get(
          `https://api.mapbox.com/directions/v5/mapbox/walking/${propertyCoordinates.join(
            ","
          )};${poiCoordinates.join(",")}`,
          {
            params: {
              access_token: mapboxgl.accessToken,
              geometries: "geojson",
            },
          }
        );

        const walkingDuration = directionsResponse.data.routes[0].duration;
        nearPlaces.push({
          name: feature.properties.name,
          address: feature?.properties?.address,
          id: feature?.properties?.mapbox_id,
          distance: calculatedDistance,
          duration: Math.round(walkingDuration / 60), // Convert to minutes
        });

        const markerElement = createIcon(
          activeTab,
          feature?.properties?.mapbox_id
        ); // Pass the activeTab as category
        const marker = new mapboxgl.Marker(markerElement)
          .setLngLat(poiCoordinates)
          .setPopup(
            new mapboxgl.Popup().setHTML(
              ` <div
               style="border: 1px solid #E2E2E2; border-radius: 4px; padding: 12px;" 
               class="explore_propertyCard"
              >
              <h3 style="font-size: 18px; font-weight: 700">${
                feature.properties.name
              }</h3>
               <p style="font-size: 16px; color: #757575; margin-bottom: 5px; font-weight: 600">${
                 calculatedDistance >= 1000
                   ? `${(calculatedDistance / 1000).toFixed(1)} km`
                   : `${Math.round(calculatedDistance)} m`
               }</p>
               <p style="font-size: 16px; color: #757575; margin-bottom: 0px; font-weight: 600">${Math.round(
                 walkingDuration / 60
               )} mins walk</p>
               </div>`
            )
          )
          .addTo(map.current);

        newMarkers.push(marker);
      });

      await Promise.all(promises);
      setNearPlacesData(nearPlaces);
      setIsLoading(false);
      setMarkers(newMarkers);
    } catch (error) {
      console.error("Error fetching POI data:", error);
    }
  };

  // Fetch POIs when the active tab changes
  useEffect(() => {
    fetchPOIData(activeTab);
    // fetichPOIData(activeTab)
  }, [activeTab]);

  useEffect(() => {
    markers.forEach(({ _element }) => {
      if (_element?.id === hoveredCardId) {
        _element.classList.add("Map_cluster_hovered");
      } else {
        _element.classList.remove("Map_cluster_hovered");
      }
    });
  }, [hoveredCardId, markers]);

  return (
    <div
      ref={mapContainer}
      style={{
        width: "100%",
        height: "250px",
        borderRadius: "20px",
        marginTop: "20px",
      }}
    />
  );
};

export default PropertyMap;
