import React, { useEffect, useState } from "react";
import {
  ComingSoonHomeIcon,
  ComingSoonBuyerIcon,
  SinglePersonIcon,
} from "../../SvgIcons/Icons";
import axios from "axios";
import baseURL from "../../config";
import { ToastContainer, toast } from "react-toastify";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { NavLink, useNavigate } from "react-router-dom";
import {
  FormControl,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  LinearProgress,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { ThreeCircles } from "react-loader-spinner";

const SubscriberInfo = () => {
  const cardData = [
    {
      role: "Realtor",
      icon: <SinglePersonIcon color={"#008733"} />,
      title: "Are you a realtor?",
      description:
        "Because this is exactly the tool you have been looking for. Discover how this platform can revolutionize your real estate business and help you Find and Sell Off-Market Properties and Assignments.",
    },
    {
      role: "Seller",
      icon: <ComingSoonHomeIcon />,
      title: "Are you looking to Sell an Off-Market Property or Assignment?",
      description:
        "Unlock exclusive tools to seamlessly sell Off-Market Assignments and connect with serious buyers.",
    },
    {
      role: "Buyer",
      icon: <ComingSoonBuyerIcon />,
      title: "Are you looking to Buy an Off-Market Property or Assignment?",
      description:
        "Gain access to Off-Market deals tailored to your preferences, offering unmatched opportunities.",
    },
  ];

  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [count, setCount] = useState(1);
  const [phone, setPhone] = useState("");
  const [userName, setUserName] = useState("");
  const [brokerageName, setBrokerageName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [cityName, setCityName] = useState("");
  const [purchaseYear, setPurchaseYear] = useState("");
  const [citySide, setCitySide] = useState("");
  const [assignmentType, setAssignmentType] = useState("");
  const [assignmentModelName, setAssignmentModelName] = useState("");
  const [budget, setBudget] = useState("");
  const [moveInDate, setMoveInDate] = useState("");
  const [inpError, setInpError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  //For progress bar work
  const roleQuestions = {
    Realtor: 3,
    Buyer: 6,
    Seller: 7,
  };

  const totalQuestions = roleQuestions[role] || 1;
  const progressPercentage = Math.min((count / totalQuestions) * 100, 100);
 
  useEffect(() => {
    const getSubcribreData = localStorage.getItem("subscriberData");
    if (!getSubcribreData) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  const handleNext = async () => {
    let isValid = true;
    let errorMessage = "";

    const validateFields = () => {
      switch (count) {
        case 1:
          if (!phone) {
            errorMessage = "Phone number is required.";
            isValid = false;
          } else if (!isValidPhoneNumber(phone, "CA")) {
            errorMessage = "Invalid phone number.";
            isValid = false;
          }
          break;
        case 2:
          if (!userName) {
            errorMessage = "Name is required.";
            isValid = false;
          }
          break;
        case 3:
          if (role === "Realtor" && !brokerageName) {
            errorMessage = "Brokerage name is required.";
            isValid = false;
          } else if (role === "Seller" && !projectName) {
            errorMessage = "Project name is required.";
            isValid = false;
          } else if (role === "Buyer" && !cityName) {
            errorMessage = "City name is required.";
            isValid = false;
          }
          break;
        case 4:
          if (role === "Seller" && !purchaseYear) {
            errorMessage = "Purchase year is required.";
            isValid = false;
          } else if (role === "Buyer" && !citySide) {
            errorMessage = "City Side is required.";
            isValid = false;
          }
          break;
        case 5:
          if ((role === "Buyer" || role === "Seller") && !assignmentType) {
            errorMessage = "Assignment type is required.";
            isValid = false;
          }
          break;
        case 6:
          if (role === "Buyer" && !budget) {
            errorMessage = "Budget is required.";
            isValid = false;
          }
          break;
        case 7:
          if (role === "Buyer" && !moveInDate) {
            errorMessage = "Move in date is required.";
            isValid = false;
          }
          break;
        default:
          break;
      }
    };

    validateFields();

    if (isValid) {
      setInpError("");
      await addNotify();
    } else {
      setInpError(errorMessage);
    }
  };

  const handlePhoneInputChange = (value) => {
    setPhone(value);
    setInpError("");
  };

  const handleDateChange = (e) => {
    setMoveInDate(e);
    setInpError("");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (!value.startsWith(" ")) {
      setInpError("");
      switch (name) {
        case "userName":
          setUserName(value);
          break;
        case "brokerageName":
          setBrokerageName(value);
          break;
        case "projectName":
          setProjectName(value);
          break;
        case "cityName":
          setCityName(value);
          break;
        case "purchaseYear":
          setPurchaseYear(value);
          break;
        case "budget":
          setBudget(value);
          break;
        case "assignmentType":
          setAssignmentType(value);
          break;
        case "citySide":
          setCitySide(value);
          break;
        case "assignmentModelName":
          setAssignmentModelName(value);
          break;
        default:
          break;
      }
    }
  };

  const addNotify = async () => {
    setIsLoading(true);

    try {
      const subscriberData = JSON.parse(localStorage.getItem("subscriberData"));

      const data = {
        ID: 1,
        Role: role,
        Email: subscriberData?.Email,
        IpAddress: subscriberData?.IpAddress,
        Address: subscriberData?.Address,
        PhoneNumber: phone,
        Name: userName || null,
        ProjectName: projectName || null,
        YearofPurchase: purchaseYear || null,
        AssignmentType: assignmentType || null,
        AssignmentModel: assignmentModelName || null,
        MoveInDate: moveInDate || null,
        Budget: budget || null,
        CitySide: citySide || null,
        BrokerageName: brokerageName || null,
      };

      const response = await axios.post(
        `${baseURL}/api/data/AddNewsLetter`,
        data
      );

      if (response?.data?.result === 1) {
        setCount(count + 1);
        if (
          (role === "Realtor" && count === 3) ||
          (role === "Seller" && count === 6) ||
          (role === "Buyer" && count === 7)
        ) {
          navigate("/thanks");
        }
      }
    } catch (e) {
      toast.error(e.message || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="coming_soonMain position-relative z-1">
      <div className="comingSoon_container px-4 pt-4 pb-5">
        <div>
          <NavLink to="/">
            <img
              className="comingSoonLogo"
              src={require("../Images/Web_logo.png")}
              alt="AssignmentSold Logo"
              loading="lazy"
            />
          </NavLink>
        </div>
        <div className="py-5 comingSoon_SubcriberInfo">
          <h4 className="text-center">
            You're all set! Let’s get to know you better
          </h4>
          <h6 className="text-center pt-4">
            A few more question if you don’t mind
          </h6>

          <div className="d-flex justify-content-center gap-5 pt-4 flex-wrap">
            {!role ? (
              <>
                {cardData?.map((card) => (
                  <div
                    className="subscriber_role d-flex flex-column justify-content-between"
                    key={card.role}
                  >
                    <div className="d-flex flex-column align-items-center">
                      <div
                        className="propertyDetail_homeValue_icon"
                        style={{
                          borderColor: "#ECFDF3",
                          background: "var(--Light-green, #D1FADF",
                        }}
                      >
                        {card.icon}
                      </div>
                      <h6 className="m-0 mb-3">{card.title}</h6>
                      <p className="m-0">{card.description}</p>
                    </div>
                    <button
                      className="btn btn-primary w-100 mt-3"
                      onClick={() => setRole(card.role)}
                    >
                      Yes
                    </button>
                  </div>
                ))}
              </>
            ) : (
              cardData
                .filter((card) => card.role === role)
                .map((card) => (
                  <div className="subscriber_role" key={card.role}>
                    <LinearProgress
                      variant="determinate"
                      value={progressPercentage}
                      style={{
                        width: "100%",
                        backgroundColor: "#f0f0f0",
                      }}
                    />
                    <div
                      className="propertyDetail_homeValue_icon mt-3"
                      style={{
                        borderColor: "#ECFDF3",
                        background: "var(--Light-green, #D1FADF",
                      }}
                    >
                      {card.icon}
                    </div>
                    <h6 className="m-0">{card.title}</h6>
                    <p className="m-0">{card.description}</p>
                    <div className="d-flex mt-3 w-100 gap-2">
                      <div className="w-75">
                        {count === 1 && (
                          <FormControl className="w-100">
                            <PhoneInput
                              size="small"
                              international
                              countryCallingCodeEditable={false}
                              defaultCountry="CA"
                              value={phone}
                              onChange={handlePhoneInputChange}
                              name="phoneNumber"
                              id="phoneNumber"
                              className="Phone_input signUp_numberInput w-100"
                              placeholder="Your Phone Number"
                            />
                          </FormControl>
                        )}

                        {count === 2 && (
                          <FormControl className="w-100">
                            <TextField
                              sx={{
                                borderRadius: "4px",
                              }}
                              size="small"
                              className="w-100"
                              value={userName}
                              onChange={handleChange}
                              name="userName"
                              id="outlined-basic1"
                              label={<span className="font">Your name</span>}
                              variant="outlined"
                            />
                          </FormControl>
                        )}

                        {count === 3 && (
                          <FormControl className="w-100">
                            <TextField
                              sx={{
                                borderRadius: "4px",
                              }}
                              size="small"
                              className="w-100"
                              value={
                                role === "Seller"
                                  ? projectName
                                  : role === "Buyer"
                                  ? cityName
                                  : brokerageName
                              }
                              onChange={handleChange}
                              name={
                                role === "Seller"
                                  ? "projectName"
                                  : role === "Buyer"
                                  ? "cityName"
                                  : "brokerageName"
                              }
                              id="outlined-basic1"
                              label={
                                <span className="font">{`${
                                  role === "Seller"
                                    ? "Project"
                                    : role === "Buyer"
                                    ? "City"
                                    : "Brokerage"
                                } name`}</span>
                              }
                              variant="outlined"
                            />
                          </FormControl>
                        )}

                        {count === 4 && role === "Seller" && (
                          <FormControl className="w-100">
                            <TextField
                              sx={{
                                borderRadius: "4px",
                              }}
                              size="small"
                              type="text"
                              className="w-100"
                              value={purchaseYear}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (
                                  value === "" ||
                                  (/^\d+$/.test(value) && parseInt(value) > 0)
                                ) {
                                  handleChange(e);
                                }
                              }}
                              name="purchaseYear"
                              id="outlined-basic1"
                              label={
                                <span className="font">Year of Purchase</span>
                              }
                              variant="outlined"
                              inputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                              }}
                            />
                          </FormControl>
                        )}

                        {count === 4 && role === "Buyer" && (
                          <FormControl fullWidth size="small" className="w-100">
                            <InputLabel id="demo-simple-select-label">
                              City Side
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={citySide}
                              onChange={handleChange}
                              label="City Side"
                              name="citySide"
                              sx={{
                                height: "41px",
                                padding: "9px",
                              }}
                              IconComponent={KeyboardArrowDownIcon}
                            >
                              <MenuItem value={"north"}>
                                {" "}
                                <span className="font">North</span>
                              </MenuItem>
                              <MenuItem value={"south"}>
                                <span className="font ">South</span>
                              </MenuItem>
                              <MenuItem value={"east"}>
                                <span className="font ">East</span>
                              </MenuItem>
                              <MenuItem value={"west"}>
                                <span className="font ">West</span>
                              </MenuItem>
                            </Select>
                          </FormControl>
                        )}

                        {count === 5 && (
                          <FormControl fullWidth size="small" className="w-100">
                            <InputLabel id="demo-simple-select-label">
                              Assignment Type
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={assignmentType}
                              label="assignmentType"
                              name="assignmentType"
                              onChange={handleChange}
                              sx={{
                                height: "41px",
                                padding: "9px",
                              }}
                            >
                              <MenuItem value={"Condo"}>
                                {" "}
                                <span className="font">Condo</span>
                              </MenuItem>
                              <MenuItem value={"Condo Town"}>
                                {" "}
                                <span className="font">Condo Town</span>
                              </MenuItem>
                              <MenuItem value={"TownHouse"}>
                                <span className="font ">TownHouse</span>
                              </MenuItem>
                              <MenuItem value={"Semi-Detached"}>
                                <span className="font ">Semi-Detached</span>
                              </MenuItem>
                              <MenuItem value={"Detached"}>
                                <span className="font ">Detached</span>
                              </MenuItem>
                            </Select>
                          </FormControl>
                        )}

                        {count === 6 && role === "Seller" && (
                          <FormControl className="w-100">
                            <TextField
                              sx={{
                                borderRadius: "4px",
                              }}
                              size="small"
                              className="w-100"
                              value={assignmentModelName}
                              onChange={handleChange}
                              name="assignmentModelName"
                              id="outlined-basic1"
                              label={
                                <span className="font">
                                  Assignment Model Name
                                </span>
                              }
                              variant="outlined"
                            />
                          </FormControl>
                        )}
                        {count === 6 && role === "Buyer" && (
                          <FormControl className="w-100">
                            <TextField
                              sx={{
                                borderRadius: "4px",
                              }}
                              size="small"
                              className="w-100"
                              value={budget?.toLocaleString("en-US")}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (
                                  value === "" ||
                                  (/^\d+$/.test(value) && parseInt(value) > 0)
                                ) {
                                  handleChange(e);
                                }
                              }}
                              name="budget"
                              id="outlined-basic1"
                              label={<span className="font">Your Budget</span>}
                              variant="outlined"
                            />
                          </FormControl>
                        )}

                        {count === 7 && role === "Buyer" && (
                          <FormControl fullWidth size="small" className="w-100">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label="Expected move in Date"
                                sx={{
                                  borderRadius: "4px",
                                  "& .MuiInputBase-input": {
                                    padding: "9px",
                                  },
                                  "& .MuiInputLabel-root": {
                                    transform: "translate(14px, 10px) scale(1)",
                                    "&.MuiInputLabel-shrink": {
                                      transform:
                                        "translate(14px, -6px) scale(0.75)",
                                    },
                                  },
                                }}
                                name="moveInDate"
                                onChange={handleDateChange}
                                minDate={dayjs().startOf("day")}
                              />
                            </LocalizationProvider>
                          </FormControl>
                        )}

                        {inpError ? (
                          <p
                            style={{ color: "red" }}
                            className="mb-0 mt-1 text-start"
                          >
                            {inpError}
                          </p>
                        ) : null}
                      </div>
                      <button
                        className={`btn btn-primary d-flex align-items-center justify-content-center w-25`}
                        style={{ height: "40px" }}
                        onClick={handleNext}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <ThreeCircles
                            visible={true}
                            height="30"
                            width="30"
                            color="#fff"
                            ariaLabel="three-circles-loading"
                          />
                        ) : (
                          `Next`
                        )}
                      </button>
                    </div>
                  </div>
                ))
            )}
          </div>
        </div>
      </div>
      <div className="position-absolute bottom-0 w-100" style={{ zIndex: -1 }}>
        <img
          src="/images/ComingSoonFooter.png"
          alt="comingSoon-footer"
          loading="lazy"
          width={"100%"}
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default SubscriberInfo;
