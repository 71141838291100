import React from "react";
import { TextField } from "@mui/material";
import "./ContactUs.css";
import Footer from "../Footer/Footer";
import { ContactSchema } from "./Contactschema";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import baseURL from "../../config";
import axios from "axios";
import { useNavigate } from "react-router";
import { useState, useEffect } from "react";
import Loader from "../../HomePage/SubComponents/MapandListing/Loader";
import HelmetLayout from "../../Utils/HelmetLayout/Helmet";
import { NavLink } from "react-router-dom";
import ContactBanner from '../../images/ContactBanner.png';
import logo from '../../images/New__LOGO.png';


export default function ContactUS() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);

  const formInitialValues = {
    fullName: "",
    email: "",
    message: "",
  };

  useEffect(() => {
    formik.setFieldValue("fullName", localStorage?.getItem("fullName"));
    formik.setFieldValue("email", localStorage?.getItem("email"));
    // eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: ContactSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        FullName: values.fullName,
        Email: values.email,
        Message: values.message,
      };

      const jsonData = JSON.stringify(data);

      setIsLoading(true);
      try {
        const response = await axios.post(
          `${baseURL}/api/data/ContactEmail`,
          jsonData,
          {
            headers: {
              "Content-Type": "application/json", // Set the content type to JSON
            },
          }
        );
        if (response?.data?.result === 1) {
          // toast.success("Message Submitted");
          setIsLoading(false);
          resetForm();
          setTimeout(() => {
            navigate("/contact-us/response");
          }, 1500);
        } else {
          toast.error(response?.data?.error);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 4000);
      }
    },
  });

  return (
    <div>
      <HelmetLayout pageName={"contact-us"} canonicalLink={"contact-us"} />
      <div className="container-fluid">
        <div className="row">
        <div className="col-lg-6 col-12">
          <img
            className="Contactimage"
            src={ContactBanner}
            alt="Contact Banner"
            loading="lazy"
          />
        </div>
        <div className="col-lg-6 contact_mobile col-12">
          <div className="adjustment mt-4">
          <div className="imagecentered">
              <NavLink to="/">
                <img
                  className="imgclass1"
                  src={logo}
                  alt="AssignmentSold logo"
                  loading="lazy"
                />
              </NavLink>
            </div>
            <h2 className="font contact_heading mb-0 mob_con_heading">Contact Us</h2>
            <form onSubmit={formik.handleSubmit}>
              <div className="mt-4 d-flex flex-column">
                <TextField
                  type="text"
                  name="fullName"
                  size="small"
                  sx={{
                    borderRadius: "4px",
                    marginBottom: "0px",
                  }}
                  className="fieldsettings mob_con_Field"
                  id="outlined-basic"
                  label={<span className="font">Enter Full name</span>}
                  variant="outlined"
                  value={formik.values.fullName}
                  onChange={formik.handleChange}
                />
                {isSubmited ? (
                  <span style={{ color: "red" }}>{formik.errors.fullName}</span>
                ) : null}
              </div>
              <div className="mt-4 d-flex flex-column">
                <TextField
                  name="email"
                  size="small"
                  sx={{
                    borderRadius: "4px",
                    marginBottom: "0px",
                  }}
                  id="outlined-basic"
                  label={<span className="font">Enter your email</span>}
                  variant="outlined"
                  className="mob_con_Field"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {isSubmited ? (
                  <span style={{ color: "red" }}>{formik.errors.email}</span>
                ) : null}
              </div>
              <div className="mt-4 d-flex flex-column">
                <TextField
                  name="message"
                  size="small"
                  sx={{
                    borderRadius: "4px",
                    marginBottom: "0px",
                  }}
                  id="outlined-basic "
                  label={<span className="font">Enter Message</span>}
                  variant="outlined"
                  multiline
                  rows={4}
                  className="mob_con_Field"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                />
                {isSubmited ? (
                  <span style={{ color: "red" }}>{formik.errors.message}</span>
                ) : null}
              </div>

             <div className="mt-4">
             {isLoading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <Loader />
                </div>
              ) : (
                <button
                  type="submit"
                  className="Submitbutton mobile_con_Submit w-100"
                  onClick={() => {
                    setIsSubmited(true);
                  }}
                >
                  Submit
                </button>
              )}
             </div>
              </form>
          </div>
        </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
