const CityBoundariesCordinatesData = [
  {
    name: "Toronto",
    coordinates: [
      [
        [-79.6392832, 43.749843601],
        [-79.6376019, 43.748342801],
        [-79.6302939, 43.735323501],
        [-79.5888158, 43.664601101],
        [-79.6087242, 43.646406401],
        [-79.6082599, 43.646069601],
        [-79.6076605, 43.644873101],
        [-79.6072166, 43.644606201],
        [-79.6055737, 43.644844901],
        [-79.6046762, 43.644445401],
        [-79.6029535, 43.644716801],
        [-79.6021847, 43.644511101],
        [-79.6017328, 43.644025801],
        [-79.5992303, 43.643697801],
        [-79.5981038, 43.643126201],
        [-79.5958333, 43.643139801],
        [-79.5929103, 43.644717101],
        [-79.5923097, 43.644777201],
        [-79.5904612, 43.644000801],
        [-79.5892731, 43.642940801],
        [-79.5883035, 43.642491501],
        [-79.5876955, 43.641078901],
        [-79.5873588, 43.640905701],
        [-79.5868556, 43.639292501],
        [-79.5852868, 43.637227301],
        [-79.5860686, 43.635357601],
        [-79.5855926, 43.634271701],
        [-79.5858675, 43.633568001],
        [-79.5853168, 43.632838801],
        [-79.5851044, 43.631907201],
        [-79.5852359, 43.631105801],
        [-79.5860767, 43.629619301],
        [-79.5854569, 43.628872901],
        [-79.5824976, 43.627960201],
        [-79.580892, 43.627775701],
        [-79.5795986, 43.626873401],
        [-79.5769378, 43.626337601],
        [-79.5751135, 43.625475601],
        [-79.5702258, 43.626956501],
        [-79.5683814, 43.626819801],
        [-79.5674129, 43.628045201],
        [-79.5669164, 43.628233701],
        [-79.5644179, 43.628131201],
        [-79.5638992, 43.627841501],
        [-79.5634661, 43.626421301],
        [-79.5636696, 43.624840301],
        [-79.5641604, 43.624386001],
        [-79.5651869, 43.624566101],
        [-79.565527, 43.624373301],
        [-79.5658872, 43.622658201],
        [-79.5655664, 43.621628401],
        [-79.566748, 43.620992101],
        [-79.5669356, 43.620516901],
        [-79.5665395, 43.617808501],
        [-79.5679048, 43.616736601],
        [-79.5682883, 43.614379101],
        [-79.5677583, 43.613828101],
        [-79.5667345, 43.613411601],
        [-79.5645487, 43.613353601],
        [-79.5639123, 43.612634301],
        [-79.5643227, 43.612220701],
        [-79.5660272, 43.611581701],
        [-79.5671524, 43.610675801],
        [-79.5671499, 43.609162601],
        [-79.5662143, 43.608447901],
        [-79.5652696, 43.608571201],
        [-79.5648144, 43.608427601],
        [-79.5645755, 43.608025301],
        [-79.5648665, 43.607449501],
        [-79.5647318, 43.607126701],
        [-79.5625386, 43.606747801],
        [-79.5617746, 43.606010801],
        [-79.5605314, 43.605521401],
        [-79.5590389, 43.604569801],
        [-79.5579488, 43.603426201],
        [-79.5564296, 43.602667901],
        [-79.5558456, 43.599781901],
        [-79.5541172, 43.599017701],
        [-79.5535972, 43.598317001],
        [-79.5539405, 43.596427401],
        [-79.5537742, 43.595816501],
        [-79.5530804, 43.595510201],
        [-79.5493977, 43.595325501],
        [-79.548847, 43.595098301],
        [-79.5485781, 43.594433301],
        [-79.5488718, 43.593168701],
        [-79.5494159, 43.592408401],
        [-79.5494258, 43.590696001],
        [-79.549242, 43.590394601],
        [-79.5471767, 43.589532001],
        [-79.5463559, 43.588960901],
        [-79.5482222, 43.586970601],
        [-79.5473997, 43.586748801],
        [-79.5445377, 43.585085201],
        [-79.5448528, 43.581969001],
        [-79.5435825, 43.580521701],
        [-79.5406949, 43.579608201],
        [-79.541716, 43.582804901],
        [-79.5277013, 43.586983401],
        [-79.5160956, 43.584835001],
        [-79.5057794, 43.585955901],
        [-79.4990562, 43.593285501],
        [-79.4870929, 43.604685001],
        [-79.4781204, 43.610042001],
        [-79.4678886, 43.621780201],
        [-79.4666293, 43.632718701],
        [-79.4543512, 43.635453001],
        [-79.4409712, 43.631693301],
        [-79.4212947, 43.625654501],
        [-79.4069701, 43.625996301],
        [-79.3898122, 43.611067801],
        [-79.3476258, 43.610384001],
        [-79.3205509, 43.616994101],
        [-79.3170878, 43.648439101],
        [-79.3129951, 43.649805901],
        [-79.298828, 43.658347801],
        [-79.2758458, 43.671215101],
        [-79.2591602, 43.688746801],
        [-79.2440486, 43.698421101],
        [-79.2331872, 43.700583401],
        [-79.2234276, 43.707866401],
        [-79.2218535, 43.715376001],
        [-79.2042233, 43.732098501],
        [-79.1881673, 43.745291201],
        [-79.1694352, 43.753705701],
        [-79.1499161, 43.757912501],
        [-79.1244154, 43.779737801],
        [-79.1132193, 43.793074601],
        [-79.1153291, 43.794870901],
        [-79.1160752, 43.794931901],
        [-79.1175, 43.794374301],
        [-79.1195256, 43.794777001],
        [-79.1209933, 43.794746001],
        [-79.1211563, 43.795260301],
        [-79.120341, 43.795551501],
        [-79.1201756, 43.796078001],
        [-79.1216284, 43.797366701],
        [-79.1221005, 43.797478201],
        [-79.1235339, 43.797211801],
        [-79.1243063, 43.797564901],
        [-79.1241776, 43.799051701],
        [-79.1246582, 43.799826101],
        [-79.125259, 43.800241201],
        [-79.1270443, 43.800142101],
        [-79.130243, 43.801657901],
        [-79.1312243, 43.801858001],
        [-79.1352412, 43.800712001],
        [-79.1352326, 43.801319101],
        [-79.133516, 43.803010201],
        [-79.134297, 43.804558901],
        [-79.1337086, 43.806263901],
        [-79.1339231, 43.806778001],
        [-79.136078, 43.808163001],
        [-79.1370565, 43.807741801],
        [-79.1375109, 43.807910301],
        [-79.1379915, 43.807409801],
        [-79.1382747, 43.807998301],
        [-79.1387897, 43.808004501],
        [-79.1393648, 43.808345101],
        [-79.1404034, 43.808363701],
        [-79.1405321, 43.808729201],
        [-79.1421886, 43.809354801],
        [-79.1445146, 43.809924601],
        [-79.1459223, 43.809980301],
        [-79.1460596, 43.811163401],
        [-79.1476303, 43.811906601],
        [-79.1480766, 43.812334001],
        [-79.149407, 43.812445501],
        [-79.1512609, 43.813950501],
        [-79.1517117, 43.813965901],
        [-79.1702147, 43.855442501],
        [-79.2034183, 43.847488501],
        [-79.2275684, 43.842129201],
        [-79.374044, 43.808159101],
        [-79.441018, 43.793429801],
        [-79.4703165, 43.787224701],
        [-79.5139046, 43.777056001],
        [-79.5731249, 43.764739701],
        [-79.6318097, 43.751469201],
        [-79.6365241, 43.751003201],
        [-79.6392832, 43.749843601],
      ],
    ],
    lat: 43.7001,
    long: -79.4163,
  },
  {
    name: "Mississauga",
    coordinates: [
      [
        [-79.8103184, 43.588243401],
        [-79.8100148, 43.587068601],
        [-79.8093311, 43.585882701],
        [-79.8067045, 43.583525901],
        [-79.7882996, 43.570354501],
        [-79.7844011, 43.567213601],
        [-79.7700379, 43.556724601],
        [-79.7626075, 43.552106501],
        [-79.7557233, 43.546172101],
        [-79.7452697, 43.538638401],
        [-79.7353538, 43.533180601],
        [-79.7351796, 43.533320901],
        [-79.7307391, 43.531276401],
        [-79.7275598, 43.530393001],
        [-79.7150803, 43.521940001],
        [-79.697352, 43.508239601],
        [-79.6816593, 43.522765001],
        [-79.6250769, 43.481370501],
        [-79.6198948, 43.478990101],
        [-79.6078528, 43.481892301],
        [-79.6000679, 43.492223301],
        [-79.5983256, 43.507669901],
        [-79.5994199, 43.524677701],
        [-79.5968922, 43.533351701],
        [-79.5900644, 43.538196501],
        [-79.5814641, 43.542622501],
        [-79.576589, 43.545662501],
        [-79.5716752, 43.549410601],
        [-79.5758208, 43.552047401],
        [-79.5668086, 43.559418401],
        [-79.5606502, 43.561159901],
        [-79.5572384, 43.561657401],
        [-79.5550599, 43.562629201],
        [-79.5536759, 43.564230701],
        [-79.5519985, 43.567961401],
        [-79.5477214, 43.570706301],
        [-79.5470562, 43.570613001],
        [-79.5421853, 43.567262601],
        [-79.5402182, 43.568816501],
        [-79.542947, 43.570651801],
        [-79.5391383, 43.572773901],
        [-79.5385553, 43.573978001],
        [-79.5399108, 43.576092901],
        [-79.5399392, 43.578377301],
        [-79.5406949, 43.579608201],
        [-79.5435825, 43.580521701],
        [-79.5448114, 43.581830001],
        [-79.5445377, 43.585085201],
        [-79.5473997, 43.586748801],
        [-79.5482222, 43.586970601],
        [-79.5463559, 43.588960901],
        [-79.5471767, 43.589532001],
        [-79.549242, 43.590394601],
        [-79.5494258, 43.590696001],
        [-79.5495117, 43.591982801],
        [-79.5488084, 43.593369901],
        [-79.5486988, 43.594932601],
        [-79.5492366, 43.595302601],
        [-79.5530804, 43.595510201],
        [-79.5537742, 43.595816501],
        [-79.5539405, 43.596427401],
        [-79.5535972, 43.598317001],
        [-79.5541172, 43.599017701],
        [-79.5558456, 43.599781901],
        [-79.5564296, 43.602667901],
        [-79.5579488, 43.603426201],
        [-79.5590389, 43.604569801],
        [-79.5605314, 43.605521401],
        [-79.5617746, 43.606010801],
        [-79.5625386, 43.606747801],
        [-79.5647318, 43.607126701],
        [-79.5648665, 43.607449501],
        [-79.5645755, 43.608025301],
        [-79.5648144, 43.608427601],
        [-79.5652696, 43.608571201],
        [-79.5662143, 43.608447901],
        [-79.5671499, 43.609162601],
        [-79.5671524, 43.610675801],
        [-79.5660272, 43.611581701],
        [-79.5643227, 43.612220701],
        [-79.5639123, 43.612634301],
        [-79.5645487, 43.613353601],
        [-79.5667345, 43.613411601],
        [-79.5677583, 43.613828101],
        [-79.5682883, 43.614379101],
        [-79.5679048, 43.616736601],
        [-79.5665395, 43.617808501],
        [-79.5669356, 43.620516901],
        [-79.566748, 43.620992101],
        [-79.5655664, 43.621628401],
        [-79.5658872, 43.622658201],
        [-79.565527, 43.624373301],
        [-79.5651869, 43.624566101],
        [-79.5641604, 43.624386001],
        [-79.5636696, 43.624840301],
        [-79.5634661, 43.626421301],
        [-79.5638992, 43.627841501],
        [-79.5644179, 43.628131201],
        [-79.5669164, 43.628233701],
        [-79.5674129, 43.628045201],
        [-79.5683814, 43.626819801],
        [-79.5702258, 43.626956501],
        [-79.5751135, 43.625475601],
        [-79.5769378, 43.626337601],
        [-79.5795986, 43.626873401],
        [-79.580892, 43.627775701],
        [-79.5824976, 43.627960201],
        [-79.5854569, 43.628872901],
        [-79.5860767, 43.629619301],
        [-79.5852359, 43.631105801],
        [-79.5851044, 43.631907201],
        [-79.5853168, 43.632838801],
        [-79.5858675, 43.633568001],
        [-79.5855926, 43.634271701],
        [-79.5860686, 43.635357601],
        [-79.5852868, 43.637227301],
        [-79.5868556, 43.639292501],
        [-79.5873588, 43.640905701],
        [-79.5876955, 43.641078901],
        [-79.5883035, 43.642491501],
        [-79.5892731, 43.642940801],
        [-79.5904612, 43.644000801],
        [-79.5923097, 43.644777201],
        [-79.5929103, 43.644717101],
        [-79.5958333, 43.643139801],
        [-79.5981038, 43.643126201],
        [-79.5992303, 43.643697801],
        [-79.6017328, 43.644025801],
        [-79.6021847, 43.644511101],
        [-79.6029535, 43.644716801],
        [-79.6046762, 43.644445401],
        [-79.6055737, 43.644844901],
        [-79.6072166, 43.644606201],
        [-79.6076605, 43.644873101],
        [-79.6082599, 43.646069601],
        [-79.6087242, 43.646406401],
        [-79.5888158, 43.664601101],
        [-79.6302939, 43.735323501],
        [-79.6322034, 43.734864801],
        [-79.634147, 43.733187101],
        [-79.6393529, 43.737060401],
        [-79.655952, 43.721985501],
        [-79.6628361, 43.716271401],
        [-79.6731408, 43.706860001],
        [-79.6725704, 43.700931501],
        [-79.6770908, 43.695195101],
        [-79.6770403, 43.694780001],
        [-79.6906899, 43.668694201],
        [-79.7075794, 43.653709801],
        [-79.7168174, 43.648924301],
        [-79.733067, 43.639761401],
        [-79.7610333, 43.614550001],
        [-79.7758145, 43.610106301],
        [-79.7760895, 43.609855301],
        [-79.7762536, 43.609974101],
        [-79.7780699, 43.609453501],
        [-79.7899154, 43.601801801],
        [-79.7879225, 43.600337101],
        [-79.7904655, 43.600081201],
        [-79.7941425, 43.599343301],
        [-79.7962644, 43.598691501],
        [-79.7994395, 43.597218201],
        [-79.8027381, 43.594964001],
        [-79.8103184, 43.588243401],
      ],
    ],
    lat: 43.589,
    long: -79.6441,
  },
  {
    name: "Vaughan",
    coordinates: [
      [
        [-79.7106948, 43.874402901],
        [-79.6976248, 43.851849401],
        [-79.6949154, 43.849923801],
        [-79.6950113, 43.849237101],
        [-79.6958012, 43.848684901],
        [-79.6455739, 43.762265901],
        [-79.6432197, 43.757948501],
        [-79.641245, 43.752449101],
        [-79.6405073, 43.751159601],
        [-79.6392832, 43.749843601],
        [-79.6365241, 43.751003201],
        [-79.6318097, 43.751469201],
        [-79.5731249, 43.764739701],
        [-79.5139046, 43.777056001],
        [-79.4703165, 43.787224701],
        [-79.4200471, 43.798039101],
        [-79.4228605, 43.809066401],
        [-79.428729, 43.834563101],
        [-79.4317292, 43.833080001],
        [-79.435465, 43.831926901],
        [-79.4539028, 43.827545801],
        [-79.4637319, 43.870043601],
        [-79.4657394, 43.877125401],
        [-79.4757924, 43.918663801],
        [-79.477773, 43.924351101],
        [-79.5873646, 43.900705301],
        [-79.7106948, 43.874402901],
      ],
    ],
    lat: 43.8372,
    long: -79.5083,
  },
  {
    name: "Oakville",
    coordinates: [
      [
        [-79.8109532, 43.434763601],
        [-79.748774, 43.389665201],
        [-79.7247985, 43.371989501],
        [-79.713939, 43.364854101],
        [-79.6198948, 43.478990101],
        [-79.6250769, 43.481370501],
        [-79.6816593, 43.522765001],
        [-79.697352, 43.508239601],
        [-79.7150803, 43.521940001],
        [-79.7248295, 43.528437101],
        [-79.7542946, 43.503359101],
        [-79.7572128, 43.500176201],
        [-79.7734716, 43.484967701],
        [-79.7863977, 43.486593001],
        [-79.7969291, 43.477083101],
        [-79.79759, 43.475052701],
        [-79.7982852, 43.474236701],
        [-79.7985771, 43.474049901],
        [-79.7995899, 43.474299001],
        [-79.8000018, 43.474168201],
        [-79.8072385, 43.467618101],
        [-79.808277, 43.466984201],
        [-79.8027111, 43.455327301],
        [-79.8007827, 43.453348801],
        [-79.7952285, 43.449243101],
        [-79.8109532, 43.434763601],
      ],
    ],
    lat: 43.4675,
    long: -79.6877,
  },
  {
    name: "North York",
    coordinates: [
      [
        [-79.585215, 43.756505601],
        [-79.584166, 43.755243801],
        [-79.5842117, 43.754282701],
        [-79.5831284, 43.753730701],
        [-79.5831059, 43.752758101],
        [-79.5828583, 43.752557501],
        [-79.5800063, 43.751578401],
        [-79.5778989, 43.750369101],
        [-79.5748092, 43.750030301],
        [-79.5732723, 43.750369401],
        [-79.5726208, 43.749693501],
        [-79.5733823, 43.747694401],
        [-79.5728515, 43.746970501],
        [-79.5656828, 43.745779101],
        [-79.5639849, 43.745265001],
        [-79.5623559, 43.744321601],
        [-79.5621693, 43.744082701],
        [-79.5624398, 43.742973901],
        [-79.5619402, 43.742673601],
        [-79.5612982, 43.742492601],
        [-79.5603056, 43.742658001],
        [-79.5595575, 43.742491201],
        [-79.5584913, 43.742930001],
        [-79.5576524, 43.741953501],
        [-79.5584601, 43.741444601],
        [-79.5578347, 43.740932401],
        [-79.5573675, 43.740902801],
        [-79.5567969, 43.741421501],
        [-79.5569838, 43.742269701],
        [-79.5564629, 43.743110101],
        [-79.5538965, 43.743276001],
        [-79.5512129, 43.744246801],
        [-79.5503739, 43.744084201],
        [-79.5497294, 43.743623501],
        [-79.5498512, 43.742945601],
        [-79.5503958, 43.742345801],
        [-79.5493376, 43.742177101],
        [-79.5491318, 43.741768701],
        [-79.548762, 43.741647101],
        [-79.5476176, 43.741742501],
        [-79.546407, 43.740955501],
        [-79.5461046, 43.740370301],
        [-79.5464299, 43.739798301],
        [-79.5482205, 43.738993301],
        [-79.548878, 43.738032801],
        [-79.5495196, 43.737778201],
        [-79.5499498, 43.737863001],
        [-79.5501627, 43.737602501],
        [-79.5500565, 43.737201301],
        [-79.5505039, 43.736215001],
        [-79.5501825, 43.735905601],
        [-79.5490575, 43.735803701],
        [-79.5492678, 43.735443501],
        [-79.5504967, 43.734699401],
        [-79.5504077, 43.733508701],
        [-79.5485468, 43.732817401],
        [-79.5468783, 43.731508401],
        [-79.5458132, 43.731307501],
        [-79.5450793, 43.731580901],
        [-79.5448962, 43.732324201],
        [-79.54408, 43.732282301],
        [-79.5438457, 43.731791801],
        [-79.544876, 43.730783201],
        [-79.5432412, 43.729880201],
        [-79.5411635, 43.729552501],
        [-79.5404334, 43.729559301],
        [-79.5395992, 43.730438701],
        [-79.5392677, 43.731130101],
        [-79.5394944, 43.731955501],
        [-79.5389254, 43.732077301],
        [-79.5384659, 43.731780401],
        [-79.5388672, 43.729268801],
        [-79.5382822, 43.726915001],
        [-79.5388167, 43.726455801],
        [-79.5404735, 43.726239201],
        [-79.5411482, 43.725932901],
        [-79.5413424, 43.725289201],
        [-79.5407678, 43.723921101],
        [-79.540791, 43.722273901],
        [-79.5428068, 43.720369401],
        [-79.5440812, 43.717713401],
        [-79.5434401, 43.717002201],
        [-79.5426578, 43.716712901],
        [-79.5420558, 43.716644401],
        [-79.5411848, 43.716869701],
        [-79.540647, 43.716746001],
        [-79.5403903, 43.716326401],
        [-79.5407845, 43.715288201],
        [-79.5403689, 43.715016601],
        [-79.5387277, 43.714833201],
        [-79.5380585, 43.714543901],
        [-79.5379271, 43.712368201],
        [-79.5396445, 43.710760101],
        [-79.5393909, 43.709657801],
        [-79.5387889, 43.708964201],
        [-79.5376826, 43.708416401],
        [-79.5360935, 43.708310301],
        [-79.53584, 43.708124901],
        [-79.5323017, 43.708975501],
        [-79.5325526, 43.709963701],
        [-79.5323504, 43.710451801],
        [-79.530868, 43.710687801],
        [-79.5315453, 43.709166001],
        [-79.5308131, 43.709303201],
        [-79.5300384, 43.707897501],
        [-79.5288337, 43.706943501],
        [-79.5254657, 43.705880101],
        [-79.5231158, 43.706451901],
        [-79.5237239, 43.707559301],
        [-79.5223409, 43.707884001],
        [-79.5222383, 43.707694501],
        [-79.5208478, 43.708048501],
        [-79.5202919, 43.706896001],
        [-79.5057916, 43.710209801],
        [-79.5028972, 43.699561901],
        [-79.5082498, 43.698349901],
        [-79.5053581, 43.696925301],
        [-79.5023828, 43.697602301],
        [-79.5021961, 43.696771701],
        [-79.5041543, 43.696361201],
        [-79.4976474, 43.692993501],
        [-79.4854659, 43.695476601],
        [-79.4422825, 43.705190301],
        [-79.4193325, 43.709963201],
        [-79.4222211, 43.717039201],
        [-79.4135028, 43.718770401],
        [-79.414567, 43.724499201],
        [-79.4158785, 43.727746401],
        [-79.4169837, 43.733346301],
        [-79.4049358, 43.736019601],
        [-79.4045552, 43.734271101],
        [-79.399992, 43.735268301],
        [-79.399939, 43.734729201],
        [-79.3985102, 43.734692801],
        [-79.3954574, 43.734163301],
        [-79.39127, 43.732448001],
        [-79.387351, 43.715467101],
        [-79.3857124, 43.711363001],
        [-79.3774928, 43.713099701],
        [-79.3780957, 43.716726001],
        [-79.3532369, 43.721720101],
        [-79.3526309, 43.716241801],
        [-79.3517949, 43.716484001],
        [-79.3511669, 43.715715501],
        [-79.3500322, 43.715826301],
        [-79.349652, 43.716157401],
        [-79.3487886, 43.716327301],
        [-79.3467657, 43.716092201],
        [-79.3469274, 43.715404901],
        [-79.3467379, 43.715178801],
        [-79.3460099, 43.715044401],
        [-79.3449091, 43.715227601],
        [-79.3447141, 43.714700101],
        [-79.3434459, 43.714089001],
        [-79.3427454, 43.713306701],
        [-79.3411074, 43.712701401],
        [-79.3400753, 43.713044401],
        [-79.3396541, 43.712941201],
        [-79.3395194, 43.712518601],
        [-79.3402664, 43.711788701],
        [-79.3402854, 43.711398401],
        [-79.3393802, 43.710603301],
        [-79.3394825, 43.710212901],
        [-79.3381387, 43.709388901],
        [-79.33779, 43.708454401],
        [-79.3378784, 43.707732101],
        [-79.3199009, 43.711667201],
        [-79.3192968, 43.712696901],
        [-79.3195366, 43.713421901],
        [-79.3195053, 43.715640801],
        [-79.3119922, 43.717280601],
        [-79.3111979, 43.715461101],
        [-79.3048153, 43.716859301],
        [-79.3053418, 43.715109401],
        [-79.2991645, 43.716381601],
        [-79.3095279, 43.741429201],
        [-79.3154374, 43.758333201],
        [-79.3194717, 43.767714801],
        [-79.3310436, 43.792277901],
        [-79.3413434, 43.815644701],
        [-79.4008678, 43.802235501],
        [-79.4703165, 43.787224701],
        [-79.5139046, 43.777056001],
        [-79.5609242, 43.767353201],
        [-79.5806472, 43.763026501],
        [-79.5795859, 43.762308001],
        [-79.5788039, 43.762060601],
        [-79.5783799, 43.762075301],
        [-79.5778052, 43.762450801],
        [-79.577493, 43.762130101],
        [-79.5785802, 43.761593601],
        [-79.5795323, 43.760735701],
        [-79.5806261, 43.760372501],
        [-79.5807699, 43.758935701],
        [-79.5820823, 43.758549301],
        [-79.5835499, 43.758753101],
        [-79.5840054, 43.758571601],
        [-79.5838648, 43.757444101],
        [-79.5849963, 43.757112701],
        [-79.585215, 43.756505601],
      ],
    ],
    lat: 43.7615,
    long: -79.4111,
  },
  {
    name: "Scarborough",
    issue: "No correct data foungd on geoApify",
    coordinates: [],
    lat: 43.7764,
    long: -79.2318,
  },
  {
    name: "Markham",
    coordinates: [
      [
        [-79.428729, 43.834563101],
        [-79.4228605, 43.809066401],
        [-79.4200471, 43.798039101],
        [-79.3451968, 43.814715201],
        [-79.2275684, 43.842129201],
        [-79.2034183, 43.847488501],
        [-79.1702147, 43.855442501],
        [-79.1835005, 43.885952401],
        [-79.1983482, 43.917732601],
        [-79.2141971, 43.952906001],
        [-79.2174147, 43.962514601],
        [-79.2286438, 43.959864501],
        [-79.2287277, 43.960230201],
        [-79.253514, 43.954745801],
        [-79.2536798, 43.955450001],
        [-79.2781504, 43.949628401],
        [-79.2781332, 43.949481601],
        [-79.3028823, 43.943876401],
        [-79.3026803, 43.943107501],
        [-79.328926, 43.937636301],
        [-79.3532685, 43.931954701],
        [-79.3530975, 43.931256501],
        [-79.3745462, 43.926356601],
        [-79.3895532, 43.923161601],
        [-79.3708618, 43.846997201],
        [-79.4000961, 43.840300801],
        [-79.4017972, 43.840078701],
        [-79.4065735, 43.840081001],
        [-79.4064355, 43.839526601],
        [-79.428729, 43.834563101],
      ],
    ],
    lat: 43.8561,
    long: -79.337,
  },
  {
    name: "Brampton",
    coordinates: [
      [
        [-79.8888473, 43.674868001],
        [-79.7899154, 43.601801801],
        [-79.7780699, 43.609453501],
        [-79.7762536, 43.609974101],
        [-79.7760895, 43.609855301],
        [-79.7758145, 43.610106301],
        [-79.7610333, 43.614550001],
        [-79.733067, 43.639761401],
        [-79.7168174, 43.648924301],
        [-79.7075794, 43.653709801],
        [-79.6906899, 43.668694201],
        [-79.6770403, 43.694780001],
        [-79.6770908, 43.695195101],
        [-79.6725704, 43.700931501],
        [-79.6731408, 43.706860001],
        [-79.6628361, 43.716271401],
        [-79.655952, 43.721985501],
        [-79.6393529, 43.737060401],
        [-79.634147, 43.733187101],
        [-79.6322034, 43.734864801],
        [-79.6302939, 43.735323501],
        [-79.6376019, 43.748342801],
        [-79.6408002, 43.751591201],
        [-79.6432197, 43.757948501],
        [-79.6455739, 43.762265901],
        [-79.6954175, 43.848095901],
        [-79.7582853, 43.792275301],
        [-79.7599751, 43.791342101],
        [-79.7673968, 43.785846901],
        [-79.7749418, 43.779716001],
        [-79.7780532, 43.775660701],
        [-79.7791381, 43.774610101],
        [-79.8033142, 43.752702601],
        [-79.8048806, 43.751373501],
        [-79.8090917, 43.748579501],
        [-79.8197357, 43.738634901],
        [-79.8250792, 43.742656401],
        [-79.8357049, 43.732930801],
        [-79.8355218, 43.732802201],
        [-79.8357338, 43.732628201],
        [-79.8327915, 43.730788801],
        [-79.8326524, 43.730888101],
        [-79.830046, 43.728982301],
        [-79.8302074, 43.728808101],
        [-79.843719, 43.716224901],
        [-79.8585919, 43.703000101],
        [-79.8636532, 43.697925401],
        [-79.869833, 43.692367301],
        [-79.8742895, 43.687265501],
        [-79.8829222, 43.679864701],
        [-79.8855373, 43.677936501],
        [-79.8888473, 43.674868001],
      ],
    ],
    lat: 43.7315,
    long: -79.7624,
  },
  {
    name: "Milton",
    coordinates: [
      [
        [-80.1611331, 43.561544801],
        [-80.0397723, 43.474977001],
        [-80.0105248, 43.453757301],
        [-79.9791374, 43.431421901],
        [-79.9778671, 43.430275001],
        [-79.9592898, 43.417272301],
        [-79.9480958, 43.427161801],
        [-79.9461619, 43.428155201],
        [-79.9451319, 43.429798301],
        [-79.9437345, 43.431150101],
        [-79.9428521, 43.432544701],
        [-79.9215903, 43.451630001],
        [-79.9188494, 43.454577701],
        [-79.9058631, 43.466704901],
        [-79.8987215, 43.472806101],
        [-79.8947465, 43.476553301],
        [-79.8679864, 43.457802201],
        [-79.8425812, 43.440475601],
        [-79.8422516, 43.440406001],
        [-79.8407494, 43.440901901],
        [-79.8396502, 43.439740401],
        [-79.837718, 43.438787701],
        [-79.8291578, 43.432602101],
        [-79.8198972, 43.426388601],
        [-79.7952285, 43.449243101],
        [-79.8007827, 43.453348801],
        [-79.8027111, 43.455327301],
        [-79.808277, 43.466984201],
        [-79.8072385, 43.467618101],
        [-79.8000018, 43.474168201],
        [-79.7995899, 43.474299001],
        [-79.7985771, 43.474049901],
        [-79.7982852, 43.474236701],
        [-79.79759, 43.475052701],
        [-79.7969291, 43.477083101],
        [-79.7863977, 43.486593001],
        [-79.7734716, 43.484967701],
        [-79.7572128, 43.500176201],
        [-79.7542946, 43.503359101],
        [-79.7248295, 43.528437101],
        [-79.7275598, 43.530393001],
        [-79.7307391, 43.531276401],
        [-79.7351796, 43.533320901],
        [-79.7353538, 43.533180601],
        [-79.7452697, 43.538638401],
        [-79.7557233, 43.546172101],
        [-79.7626075, 43.552106501],
        [-79.7700379, 43.556724601],
        [-79.7844011, 43.567213601],
        [-79.7882996, 43.570354501],
        [-79.8067045, 43.583525901],
        [-79.8093311, 43.585882701],
        [-79.8100148, 43.587068601],
        [-79.8103184, 43.588243401],
        [-79.8594712, 43.544112101],
        [-79.8626166, 43.542087101],
        [-79.8669372, 43.545603301],
        [-79.8683067, 43.544332701],
        [-79.8949935, 43.564227201],
        [-79.939066, 43.524630001],
        [-80.085391, 43.628836001],
        [-80.086049, 43.628495601],
        [-80.1342803, 43.585511401],
        [-80.1285511, 43.581349301],
        [-80.1395639, 43.571851101],
        [-80.138666, 43.571271701],
        [-80.144284, 43.566477901],
        [-80.1507408, 43.570950001],
        [-80.1611331, 43.561544801],
      ],
    ],
    lat: 43.5183,
    long: -79.8774,
  },
  {
    name: "Richmond",
    issue: "There is two richmond",
    coordinates: [],
    lat: 49.1666,
    long: -123.1336,
  },
  {
    name: "Richmond Hill",
    coordinates: [
      [
        [-79.4855416, 43.957468501],
        [-79.4814178, 43.938934801],
        [-79.477773, 43.924351101],
        [-79.4757924, 43.918663801],
        [-79.4657394, 43.877125401],
        [-79.4637319, 43.870043601],
        [-79.4539028, 43.827545801],
        [-79.435465, 43.831926901],
        [-79.4317292, 43.833080001],
        [-79.428729, 43.834563101],
        [-79.4064355, 43.839526601],
        [-79.4065735, 43.840081001],
        [-79.4017972, 43.840078701],
        [-79.4000961, 43.840300801],
        [-79.3708618, 43.846997201],
        [-79.3901681, 43.925917401],
        [-79.3902754, 43.928915601],
        [-79.3893956, 43.936703801],
        [-79.3896316, 43.939438701],
        [-79.3923142, 43.951104701],
        [-79.392679, 43.954379601],
        [-79.3929365, 43.958457501],
        [-79.3925288, 43.972295601],
        [-79.3928828, 43.977695601],
        [-79.4097644, 43.973368101],
        [-79.4497722, 43.964741101],
        [-79.4637331, 43.962092301],
        [-79.4811807, 43.958105801],
        [-79.4855416, 43.957468501],
      ],
    ],
    lat: 43.8828,
    long: -79.4403,
  },
  {
    name: "Etobicoke",
    issue: "change in geoApify",
    coordinates: [],
    lat: 43.6205,
    long: -79.5132,
  },
  {
    name: "Pickering",
    coordinates: [
      [
        [-79.2200381, 43.970920301],
        [-79.2141971, 43.952906001],
        [-79.1983482, 43.917732601],
        [-79.1835005, 43.885952401],
        [-79.1696452, 43.854057201],
        [-79.1517117, 43.813965901],
        [-79.1512609, 43.813950501],
        [-79.149407, 43.812445501],
        [-79.1480766, 43.812334001],
        [-79.1476303, 43.811906601],
        [-79.1460596, 43.811163401],
        [-79.1459223, 43.809980301],
        [-79.1445146, 43.809924601],
        [-79.1421886, 43.809354801],
        [-79.1405321, 43.808729201],
        [-79.1404034, 43.808363701],
        [-79.1393648, 43.808345101],
        [-79.1387897, 43.808004501],
        [-79.1382747, 43.807998301],
        [-79.1379915, 43.807409801],
        [-79.1375109, 43.807910301],
        [-79.1370565, 43.807741801],
        [-79.136078, 43.808163001],
        [-79.1339231, 43.806778001],
        [-79.1337086, 43.806263901],
        [-79.134297, 43.804558901],
        [-79.133516, 43.803010201],
        [-79.1352326, 43.801319101],
        [-79.1352412, 43.800712001],
        [-79.1312243, 43.801858001],
        [-79.130243, 43.801657901],
        [-79.1270443, 43.800142101],
        [-79.125259, 43.800241201],
        [-79.1246582, 43.799826101],
        [-79.1241776, 43.799051701],
        [-79.1243063, 43.797564901],
        [-79.1235339, 43.797211801],
        [-79.1221005, 43.797478201],
        [-79.1216284, 43.797366701],
        [-79.1201756, 43.796078001],
        [-79.120341, 43.795551501],
        [-79.1211563, 43.795260301],
        [-79.1209933, 43.794746001],
        [-79.1195256, 43.794777001],
        [-79.1175, 43.794374301],
        [-79.1160752, 43.794931901],
        [-79.1153291, 43.794870901],
        [-79.1132193, 43.793074601],
        [-79.0351812, 43.805831901],
        [-79.0402577, 43.819784101],
        [-79.0474138, 43.836971201],
        [-79.047763, 43.837446601],
        [-79.0480993, 43.837380101],
        [-79.0515501, 43.845488401],
        [-79.0559085, 43.844324701],
        [-79.0608723, 43.842029601],
        [-79.0665164, 43.854997501],
        [-79.0678787, 43.854735001],
        [-79.0697944, 43.855329101],
        [-79.0706133, 43.854334201],
        [-79.0726101, 43.859072301],
        [-79.0787863, 43.870688001],
        [-79.0698243, 43.875988901],
        [-79.0746856, 43.887563601],
        [-79.075213, 43.889702701],
        [-79.0773731, 43.894695501],
        [-79.0826016, 43.892712301],
        [-79.0869764, 43.903739001],
        [-79.0879551, 43.903529901],
        [-79.0882994, 43.904252801],
        [-79.078062, 43.906550801],
        [-79.0766554, 43.907114201],
        [-79.0676033, 43.908926601],
        [-79.0085864, 43.922625001],
        [-79.0169456, 43.940242101],
        [-79.037247, 43.985776901],
        [-79.0480084, 44.010758201],
        [-79.2200381, 43.970920301],
      ],
    ],
    lat: 43.835,
    long: -79.089,
  },
  {
    name: "Oshawa",
    coordinates: [
      [
        [-78.9587724, 44.028819401],
        [-78.928672, 43.961178201],
        [-78.9219772, 43.945576201],
        [-78.9171707, 43.933592401],
        [-78.9073839, 43.911611901],
        [-78.9027163, 43.900031301],
        [-78.895031, 43.882787701],
        [-78.8938186, 43.880583801],
        [-78.881571, 43.852433801],
        [-78.874655, 43.832065301],
        [-78.7879444, 43.846230901],
        [-78.7969647, 43.867300101],
        [-78.8015137, 43.878994201],
        [-78.8065953, 43.891063501],
        [-78.8518896, 43.993923801],
        [-78.8751467, 44.047428601],
        [-78.9587724, 44.028819401],
      ],
    ],
    lat: 43.8975,
    long: -78.8658,
  },
  {
    name: "Whitby",
    coordinates: [
      [
        [-79.0473097, 44.009242701],
        [-79.037247, 43.985776901],
        [-79.0169456, 43.940242101],
        [-79.0075559, 43.920472801],
        [-79.0013802, 43.905211801],
        [-78.9934063, 43.887015601],
        [-78.9704614, 43.832756401],
        [-78.9645592, 43.817374401],
        [-78.874655, 43.832065301],
        [-78.881571, 43.852433801],
        [-78.8938186, 43.880583801],
        [-78.895031, 43.882787701],
        [-78.9027163, 43.900031301],
        [-78.9073839, 43.911611901],
        [-78.9171707, 43.933592401],
        [-78.9219772, 43.945576201],
        [-78.928672, 43.961178201],
        [-78.9587724, 44.028819401],
        [-79.0473097, 44.009242701],
      ],
    ],
    lat: 43.8971,
    long: -78.9429,
  },
  {
    name: "Barrie",
    coordinates: [
      [
        [-79.7456508, 44.362029601],
        [-79.7279091, 44.293748101],
        [-79.6544039, 44.310133401],
        [-79.659275, 44.328553901],
        [-79.5858974, 44.345342701],
        [-79.5901164, 44.362288701],
        [-79.5895585, 44.364773901],
        [-79.5907579, 44.369833401],
        [-79.6204172, 44.363180401],
        [-79.621939, 44.368356101],
        [-79.6228456, 44.368185501],
        [-79.6265739, 44.384095801],
        [-79.6509559, 44.386577001],
        [-79.6501086, 44.393669201],
        [-79.6460191, 44.415350801],
        [-79.6559861, 44.416249101],
        [-79.6555502, 44.419998601],
        [-79.6719058, 44.421309701],
        [-79.6827001, 44.416945601],
        [-79.6837708, 44.418252201],
        [-79.6844424, 44.417965601],
        [-79.6898499, 44.424315801],
        [-79.6971041, 44.421115101],
        [-79.7000832, 44.424591301],
        [-79.7073775, 44.421330701],
        [-79.7071296, 44.421032301],
        [-79.7222315, 44.414320901],
        [-79.7200257, 44.411648901],
        [-79.7275852, 44.408307501],
        [-79.7269336, 44.407544401],
        [-79.7282059, 44.405192101],
        [-79.7324538, 44.402096501],
        [-79.7304589, 44.399816501],
        [-79.7453291, 44.393307001],
        [-79.7423592, 44.389973001],
        [-79.7417049, 44.386750101],
        [-79.7421028, 44.383133901],
        [-79.741826, 44.381932201],
        [-79.7447989, 44.380670101],
        [-79.7407462, 44.375984101],
        [-79.7419865, 44.375385801],
        [-79.7413256, 44.374521501],
        [-79.7409711, 44.374663701],
        [-79.7403032, 44.374297201],
        [-79.7400028, 44.373916401],
        [-79.7402055, 44.373822901],
        [-79.7386821, 44.371877201],
        [-79.7381864, 44.371910601],
        [-79.7376735, 44.371305901],
        [-79.7380383, 44.371119101],
        [-79.7376977, 44.370774001],
        [-79.7371371, 44.370901701],
        [-79.736112, 44.369734401],
        [-79.7355707, 44.369866301],
        [-79.7352011, 44.369470201],
        [-79.7358212, 44.369248601],
        [-79.7352129, 44.368487001],
        [-79.7363217, 44.367983101],
        [-79.7356302, 44.367088401],
        [-79.7337721, 44.367914301],
        [-79.7314916, 44.365311701],
        [-79.7456508, 44.362029601],
      ],
    ],
    lat: 44.3894,
    long: -79.6903,
  },
  {
    name: "Hamilton",
    coordinates: [
      [
        [-80.2485579, 43.333494101],
        [-80.2384036, 43.306513001],
        [-80.1990076, 43.209827001],
        [-80.011965, 43.141738401],
        [-79.9108809, 43.105917301],
        [-79.8353973, 43.079447001],
        [-79.7952714, 43.064838101],
        [-79.7539601, 43.050507301],
        [-79.7098099, 43.155730401],
        [-79.6582567, 43.143974401],
        [-79.6496842, 43.163093601],
        [-79.6221146, 43.226391801],
        [-79.6230537, 43.226369101],
        [-79.6278226, 43.228913701],
        [-79.6323609, 43.228218001],
        [-79.6492159, 43.223699401],
        [-79.6533895, 43.223308501],
        [-79.654516, 43.223003601],
        [-79.6609748, 43.224778201],
        [-79.6658456, 43.226412101],
        [-79.6734631, 43.231063401],
        [-79.6747398, 43.230907101],
        [-79.6816814, 43.234252701],
        [-79.6863377, 43.234260501],
        [-79.6895349, 43.236542901],
        [-79.6903718, 43.236277101],
        [-79.6907151, 43.235644001],
        [-79.6936011, 43.236363101],
        [-79.6962834, 43.235956701],
        [-79.7045553, 43.236120801],
        [-79.7061753, 43.235753401],
        [-79.7156167, 43.238793901],
        [-79.7233844, 43.243022301],
        [-79.7278905, 43.244749501],
        [-79.7341562, 43.246250001],
        [-79.739027, 43.246359401],
        [-79.7432327, 43.246124901],
        [-79.747653, 43.247281501],
        [-79.7617293, 43.257830801],
        [-79.7700334, 43.265331301],
        [-79.7766209, 43.273080901],
        [-79.7846031, 43.284078701],
        [-79.7939265, 43.298838201],
        [-79.7897744, 43.301016701],
        [-79.7909895, 43.301986001],
        [-79.7988925, 43.297753901],
        [-79.799459, 43.297491601],
        [-79.798223, 43.298228701],
        [-79.7984719, 43.298478501],
        [-79.8017133, 43.296976101],
        [-79.8035703, 43.299659201],
        [-79.8031754, 43.299834101],
        [-79.8034844, 43.300265101],
        [-79.8043084, 43.300002701],
        [-79.8034501, 43.300340001],
        [-79.8035788, 43.300833501],
        [-79.800886, 43.301967101],
        [-79.8007115, 43.302526301],
        [-79.8009728, 43.303457001],
        [-79.801347, 43.303925201],
        [-79.8017761, 43.303844001],
        [-79.8020033, 43.304069901],
        [-79.8016817, 43.304487401],
        [-79.8023834, 43.305292101],
        [-79.8022396, 43.306017601],
        [-79.8036472, 43.308097501],
        [-79.804077, 43.308224001],
        [-79.8046944, 43.310708101],
        [-79.8065483, 43.312157101],
        [-79.8093736, 43.313044201],
        [-79.8122832, 43.313369001],
        [-79.8129699, 43.313237801],
        [-79.8168666, 43.312107401],
        [-79.8172099, 43.311751401],
        [-79.8181111, 43.311420401],
        [-79.8215167, 43.310711101],
        [-79.8220639, 43.310197301],
        [-79.8247359, 43.309388401],
        [-79.8271618, 43.307901701],
        [-79.8275735, 43.307932701],
        [-79.8276313, 43.308285001],
        [-79.828199, 43.308278501],
        [-79.8282751, 43.307273201],
        [-79.8289041, 43.306996101],
        [-79.831704, 43.307260801],
        [-79.8333502, 43.306409001],
        [-79.8334875, 43.306096701],
        [-79.8338308, 43.306215301],
        [-79.8344316, 43.306015501],
        [-79.8348865, 43.306177901],
        [-79.8354788, 43.305696901],
        [-79.8365946, 43.305490801],
        [-79.8372726, 43.304897401],
        [-79.8378906, 43.305109801],
        [-79.8378391, 43.304916201],
        [-79.8385687, 43.304984901],
        [-79.8385772, 43.304522701],
        [-79.8396036, 43.304339301],
        [-79.8389772, 43.304228901],
        [-79.8390851, 43.303734701],
        [-79.8402799, 43.303159601],
        [-79.8406773, 43.303231901],
        [-79.8408538, 43.302943501],
        [-79.8411613, 43.303080401],
        [-79.8420214, 43.302793601],
        [-79.8419075, 43.302648801],
        [-79.8432628, 43.302556301],
        [-79.8452423, 43.301076901],
        [-79.8443757, 43.300311701],
        [-79.8448222, 43.299960301],
        [-79.8452092, 43.300185801],
        [-79.8454301, 43.299933501],
        [-79.8443182, 43.298951901],
        [-79.8447939, 43.298979001],
        [-79.846464, 43.300510901],
        [-79.8506572, 43.298555501],
        [-79.850631, 43.298329101],
        [-79.8508318, 43.298468701],
        [-79.8542018, 43.296895001],
        [-79.857429, 43.294789801],
        [-79.858112, 43.293891001],
        [-79.8640294, 43.290354201],
        [-79.8652997, 43.288848501],
        [-79.8666215, 43.287798901],
        [-79.8672309, 43.287748901],
        [-79.867895, 43.287317001],
        [-79.868057, 43.286409501],
        [-79.8683918, 43.286284501],
        [-79.8699261, 43.287008501],
        [-79.8716169, 43.287420801],
        [-79.8739172, 43.286852301],
        [-79.8754499, 43.287848701],
        [-79.8751572, 43.288137901],
        [-79.87564, 43.288526401],
        [-79.8774196, 43.287950601],
        [-79.8811055, 43.287971701],
        [-79.8838861, 43.288305701],
        [-79.8850103, 43.287930001],
        [-79.8861455, 43.287905501],
        [-79.8860523, 43.289056401],
        [-79.8875048, 43.289467101],
        [-79.8872754, 43.289664801],
        [-79.8873441, 43.290195901],
        [-79.8910561, 43.293142101],
        [-79.9124929, 43.308423401],
        [-79.8834734, 43.329820001],
        [-79.8803328, 43.328095201],
        [-79.8763952, 43.329035401],
        [-79.8736067, 43.330625101],
        [-79.8697612, 43.334392101],
        [-79.8674049, 43.335664601],
        [-79.866726, 43.335825001],
        [-79.8666007, 43.336414401],
        [-79.8657062, 43.336796901],
        [-79.8654515, 43.337983701],
        [-79.8639798, 43.339025401],
        [-79.8629784, 43.340238101],
        [-79.8612411, 43.341406801],
        [-79.8606075, 43.342762501],
        [-79.8606946, 43.343385301],
        [-79.859594, 43.345212801],
        [-79.8596962, 43.346411801],
        [-79.8601049, 43.347304301],
        [-79.8606343, 43.347446001],
        [-79.9102668, 43.382909701],
        [-79.9778671, 43.430275001],
        [-79.9791374, 43.431421901],
        [-80.0105248, 43.453757301],
        [-80.0338483, 43.470680501],
        [-80.0838247, 43.435096801],
        [-80.0785907, 43.418121001],
        [-80.2064335, 43.397174701],
        [-80.2033728, 43.387959501],
        [-80.2027776, 43.388062401],
        [-80.1974647, 43.370296901],
        [-80.1981015, 43.370188101],
        [-80.1892866, 43.343514501],
        [-80.2485579, 43.333494101],
      ],
    ],
    lat: 43.2557,
    long: -79.8711,
  },
  {
    name: "Ottawa",
    coordinates: [
      [
        [-76.3555857, 45.4093948],
        [-76.278548, 45.3501829],
        [-76.2307518, 45.313815],
        [-76.2144423, 45.3008177],
        [-76.2105976, 45.3031213],
        [-76.1626549, 45.2631857],
        [-76.1477745, 45.2503327],
        [-76.1109438, 45.2207253],
        [-76.1060786, 45.2164849],
        [-76.0903093, 45.2041208],
        [-76.0750646, 45.1915481],
        [-76.067258, 45.1854197],
        [-75.9652903, 45.1008122],
        [-75.9640726, 45.1002194],
        [-75.963598, 45.0994759],
        [-75.9521531, 45.0898163],
        [-75.9410204, 45.0800131],
        [-75.9414878, 45.0794686],
        [-75.953094, 45.0723026],
        [-75.9317695, 45.0548564],
        [-75.9159634, 45.0410333],
        [-75.9000737, 45.0280449],
        [-75.8923678, 45.0211343],
        [-75.8699696, 45.0020025],
        [-75.8389538, 44.9779226],
        [-75.8194254, 44.9617738],
        [-75.8190613, 44.9629959],
        [-75.8193512, 44.9644386],
        [-75.8210142, 44.966137],
        [-75.820961, 44.9675192],
        [-75.8197556, 44.9680772],
        [-75.8168334, 44.9684041],
        [-75.8143466, 44.9690608],
        [-75.8133777, 44.9696484],
        [-75.8118139, 44.974828],
        [-75.807252, 44.978277],
        [-75.8072579, 44.9786685],
        [-75.8066101, 44.9792115],
        [-75.8050536, 44.9798527],
        [-75.8036193, 44.9801148],
        [-75.8016662, 44.9801464],
        [-75.8002701, 44.9805433],
        [-75.7997436, 44.9817622],
        [-75.7974765, 44.9821793],
        [-75.7961276, 44.9828094],
        [-75.7932737, 44.9824915],
        [-75.7889481, 44.9826611],
        [-75.7854386, 44.98332],
        [-75.7793962, 44.9863454],
        [-75.7686387, 44.9903163],
        [-75.7670593, 44.9916006],
        [-75.7652132, 44.9937195],
        [-75.7646865, 44.9949969],
        [-75.7650269, 44.9972021],
        [-75.7643355, 44.9982083],
        [-75.762787, 44.9993215],
        [-75.7610016, 44.9995493],
        [-75.7591325, 45.0001739],
        [-75.758438, 45.0010157],
        [-75.7586978, 45.0023868],
        [-75.7581809, 45.003339],
        [-75.7572093, 45.0040734],
        [-75.7511821, 45.0051371],
        [-75.7488542, 45.008163],
        [-75.7473924, 45.0090758],
        [-75.7456757, 45.0096083],
        [-75.7432702, 45.0094846],
        [-75.7418092, 45.0098031],
        [-75.7398508, 45.0107718],
        [-75.7380418, 45.0123727],
        [-75.7364983, 45.0128046],
        [-75.7358263, 45.0132402],
        [-75.729124, 45.0185643],
        [-75.7277335, 45.0193832],
        [-75.7249209, 45.0201095],
        [-75.7223215, 45.0198619],
        [-75.7197591, 45.019948],
        [-75.7175293, 45.0202693],
        [-75.7153275, 45.0210516],
        [-75.7127486, 45.0233668],
        [-75.7112279, 45.0238206],
        [-75.7086868, 45.023948],
        [-75.7049763, 45.0251549],
        [-75.701866, 45.027762],
        [-75.7001723, 45.0299822],
        [-75.6973877, 45.0322181],
        [-75.6977614, 45.0369241],
        [-75.6931154, 45.0406609],
        [-75.6912005, 45.0435533],
        [-75.690369, 45.0457774],
        [-75.6894453, 45.0472817],
        [-75.6883622, 45.0477878],
        [-75.6867141, 45.0472982],
        [-75.680229, 45.0472198],
        [-75.6757582, 45.052589],
        [-75.6726607, 45.0543806],
        [-75.6662828, 45.0568576],
        [-75.6595839, 45.0586519],
        [-75.6578091, 45.0593201],
        [-75.6493226, 45.0634906],
        [-75.6423414, 45.0684045],
        [-75.6397554, 45.0746799],
        [-75.6391907, 45.0799986],
        [-75.6378403, 45.0852092],
        [-75.6366197, 45.088101],
        [-75.6333617, 45.0902166],
        [-75.6268313, 45.0932457],
        [-75.6218643, 45.0968195],
        [-75.6187974, 45.1028765],
        [-75.6185227, 45.1061996],
        [-75.6230868, 45.113172],
        [-75.6259215, 45.1181162],
        [-75.6276628, 45.1202863],
        [-75.6286273, 45.1222956],
        [-75.628788, 45.1239835],
        [-75.6284893, 45.1258155],
        [-75.6239676, 45.1204877],
        [-75.6200201, 45.1148573],
        [-75.6134148, 45.1063197],
        [-75.613005, 45.1052342],
        [-75.6123474, 45.1049575],
        [-75.605444, 45.0962915],
        [-75.5950794, 45.0843804],
        [-75.549672, 45.1045493],
        [-75.5058487, 45.1224065],
        [-75.4954967, 45.1270307],
        [-75.4954082, 45.1268718],
        [-75.474377, 45.1336566],
        [-75.4501299, 45.1421178],
        [-75.4333553, 45.1483437],
        [-75.427441, 45.1506439],
        [-75.4275859, 45.1507971],
        [-75.3901235, 45.1667213],
        [-75.3531787, 45.1820935],
        [-75.3651917, 45.2007977],
        [-75.3688168, 45.206882],
        [-75.3815785, 45.2285842],
        [-75.4010672, 45.2628016],
        [-75.4108639, 45.2817784],
        [-75.4259397, 45.312479],
        [-75.4070694, 45.3177669],
        [-75.3763164, 45.3257333],
        [-75.3300494, 45.3382041],
        [-75.2681762, 45.3561295],
        [-75.2692561, 45.3578813],
        [-75.2465783, 45.3644456],
        [-75.2593607, 45.3870006],
        [-75.2703246, 45.4071392],
        [-75.2715175, 45.4088508],
        [-75.2716658, 45.4096587],
        [-75.2927657, 45.450696],
        [-75.3025905, 45.4689544],
        [-75.3151658, 45.491585],
        [-75.3414953, 45.5376502],
        [-75.3522956, 45.5346529],
        [-75.3619613, 45.5322686],
        [-75.3729472, 45.5298232],
        [-75.3791662, 45.5287934],
        [-75.3838419, 45.5272101],
        [-75.386757, 45.5258598],
        [-75.3893961, 45.5242309],
        [-75.3914268, 45.523594],
        [-75.3974916, 45.5232659],
        [-75.4057001, 45.5232924],
        [-75.4092935, 45.5235403],
        [-75.4116056, 45.5233993],
        [-75.4143829, 45.522705],
        [-75.4191896, 45.5205078],
        [-75.4213021, 45.5199698],
        [-75.4237673, 45.519661],
        [-75.4338829, 45.5199469],
        [-75.4455331, 45.5206794],
        [-75.4503864, 45.5196153],
        [-75.4579625, 45.5183984],
        [-75.4681086, 45.5151595],
        [-75.4786065, 45.5134429],
        [-75.4923473, 45.5089645],
        [-75.511612, 45.50177],
        [-75.5209887, 45.4985581],
        [-75.5345611, 45.4933662],
        [-75.5475844, 45.4864998],
        [-75.5497129, 45.4858131],
        [-75.5546346, 45.4846727],
        [-75.5582884, 45.483387],
        [-75.5629125, 45.4808503],
        [-75.5673065, 45.4778862],
        [-75.5705953, 45.476204],
        [-75.5781396, 45.4739265],
        [-75.5816571, 45.4733314],
        [-75.5889669, 45.472603],
        [-75.5981058, 45.4721946],
        [-75.6148144, 45.4720039],
        [-75.6192473, 45.4714851],
        [-75.6233287, 45.4703673],
        [-75.6301579, 45.4677087],
        [-75.6365206, 45.4661445],
        [-75.658272, 45.4628335],
        [-75.6761625, 45.4605408],
        [-75.6816024, 45.459465],
        [-75.6854548, 45.4578933],
        [-75.6903158, 45.4546701],
        [-75.6923319, 45.4526885],
        [-75.6955267, 45.4487497],
        [-75.7028501, 45.4371489],
        [-75.7043235, 45.4269754],
        [-75.7085501, 45.4238684],
        [-75.7095264, 45.4232979],
        [-75.7177548, 45.421626],
        [-75.7195008, 45.4209706],
        [-75.7216251, 45.420805],
        [-75.7226551, 45.4200133],
        [-75.7288443, 45.4172262],
        [-75.7299655, 45.4172059],
        [-75.7334143, 45.4160806],
        [-75.7346492, 45.4161567],
        [-75.7374504, 45.4152948],
        [-75.747261, 45.4133568],
        [-75.7514656, 45.4133265],
        [-75.7532127, 45.413109],
        [-75.7550728, 45.4126662],
        [-75.757194, 45.4118498],
        [-75.760223, 45.4098243],
        [-75.7626807, 45.4065744],
        [-75.7651218, 45.4046327],
        [-75.7690586, 45.3983232],
        [-75.7733458, 45.3931541],
        [-75.777558, 45.3891641],
        [-75.7823872, 45.3854485],
        [-75.7861482, 45.3830299],
        [-75.7900773, 45.3808441],
        [-75.7998662, 45.3763352],
        [-75.806648, 45.3740844],
        [-75.8131176, 45.3728484],
        [-75.8184514, 45.3724138],
        [-75.8232802, 45.3724518],
        [-75.8377831, 45.3740461],
        [-75.8451159, 45.377388],
        [-75.8552851, 45.3799591],
        [-75.8686748, 45.3844108],
        [-75.902794, 45.4069634],
        [-75.9077308, 45.4106256],
        [-75.9210888, 45.4218063],
        [-75.9274067, 45.4278566],
        [-75.9331967, 45.4340743],
        [-75.9450452, 45.4492073],
        [-75.9531942, 45.457802],
        [-75.9631122, 45.466465],
        [-75.9664537, 45.4697685],
        [-75.9678192, 45.4716111],
        [-75.969558, 45.4733694],
        [-75.9719474, 45.4751664],
        [-75.9740902, 45.4764022],
        [-75.9769055, 45.4776381],
        [-75.9799352, 45.4785729],
        [-75.9913937, 45.4804725],
        [-76.0042185, 45.4841803],
        [-76.0143206, 45.4865914],
        [-76.0234068, 45.4902381],
        [-76.0427323, 45.496254],
        [-76.0813296, 45.5104142],
        [-76.0878903, 45.5156898],
        [-76.0884318, 45.515911],
        [-76.0915839, 45.5167467],
        [-76.0965573, 45.5172539],
        [-76.1148152, 45.5172768],
        [-76.1380617, 45.5187417],
        [-76.1413573, 45.5184555],
        [-76.1477895, 45.5172464],
        [-76.1519242, 45.5167007],
        [-76.1692426, 45.5158881],
        [-76.1725544, 45.5160866],
        [-76.1814581, 45.5176966],
        [-76.1867215, 45.5176696],
        [-76.1921916, 45.5179901],
        [-76.2005227, 45.5178374],
        [-76.2045894, 45.5176353],
        [-76.2077104, 45.5172158],
        [-76.2134171, 45.5160293],
        [-76.2172089, 45.5149994],
        [-76.2278137, 45.5114289],
        [-76.2305982, 45.5100403],
        [-76.233284, 45.5081825],
        [-76.2341691, 45.5066452],
        [-76.238746, 45.4750085],
        [-76.2395326, 45.4721719],
        [-76.2408517, 45.4698981],
        [-76.2414475, 45.4693985],
        [-76.2435459, 45.468483],
        [-76.2469408, 45.4679603],
        [-76.2506711, 45.4678496],
        [-76.2616427, 45.4681702],
        [-76.2729345, 45.4674492],
        [-76.2769997, 45.4667815],
        [-76.2833252, 45.4665756],
        [-76.289749, 45.4659194],
        [-76.2931042, 45.4561057],
        [-76.2999943, 45.4451667],
        [-76.3113477, 45.4379609],
        [-76.3126575, 45.4382854],
        [-76.3135857, 45.4380677],
        [-76.312822, 45.4376624],
        [-76.3127873, 45.4372758],
        [-76.3131658, 45.4370554],
        [-76.3136815, 45.4372385],
        [-76.3142263, 45.4370252],
        [-76.3132358, 45.4367665],
        [-76.3130227, 45.4364001],
        [-76.3152548, 45.4354812],
        [-76.3522707, 45.4118266],
        [-76.3555857, 45.4093948],
      ],
    ],
    lat: 45.4215,
    long: -75.6993,
  },
  {
    name: "Guelph",
    coordinates: [
      [
        [-80.3269091, 43.534516601],
        [-80.307034, 43.519895501],
        [-80.2976179, 43.523635401],
        [-80.291076, 43.518994301],
        [-80.2891022, 43.518283301],
        [-80.2792336, 43.511216401],
        [-80.2781283, 43.512022901],
        [-80.2772423, 43.511404901],
        [-80.2744739, 43.513434601],
        [-80.2728048, 43.512299701],
        [-80.2711949, 43.516389201],
        [-80.2694607, 43.516618501],
        [-80.269622, 43.517365301],
        [-80.2679462, 43.517595501],
        [-80.2682916, 43.519030401],
        [-80.2674549, 43.519124901],
        [-80.2675565, 43.519714701],
        [-80.264553, 43.521930401],
        [-80.2642007, 43.521709101],
        [-80.2643645, 43.521043401],
        [-80.2639756, 43.519901701],
        [-80.2642491, 43.518207601],
        [-80.2638415, 43.516023301],
        [-80.2629134, 43.515255001],
        [-80.2613738, 43.514545001],
        [-80.2609286, 43.513533601],
        [-80.259735, 43.512537601],
        [-80.2589763, 43.511473801],
        [-80.2569233, 43.509817701],
        [-80.2566734, 43.508690601],
        [-80.2547965, 43.506382901],
        [-80.2548104, 43.505257301],
        [-80.2540937, 43.504180601],
        [-80.2541197, 43.502073001],
        [-80.2534183, 43.499773501],
        [-80.2526421, 43.499515101],
        [-80.2463232, 43.500517501],
        [-80.2436101, 43.494594501],
        [-80.233387, 43.496078901],
        [-80.2298879, 43.487286201],
        [-80.2269416, 43.478350201],
        [-80.2013446, 43.482624401],
        [-80.1891533, 43.473821701],
        [-80.1535013, 43.500519101],
        [-80.2033721, 43.537152601],
        [-80.1856693, 43.550326701],
        [-80.1885373, 43.552407501],
        [-80.1877136, 43.553014001],
        [-80.1919401, 43.556082701],
        [-80.1921689, 43.555913401],
        [-80.1939088, 43.557177201],
        [-80.1936952, 43.557342201],
        [-80.1961064, 43.559092501],
        [-80.195221, 43.559743601],
        [-80.1969604, 43.560971401],
        [-80.1950131, 43.562439401],
        [-80.2250573, 43.583961901],
        [-80.2288307, 43.581289901],
        [-80.233412, 43.584604001],
        [-80.244093, 43.576757201],
        [-80.2684885, 43.594353001],
        [-80.2695757, 43.593818201],
        [-80.269942, 43.593235201],
        [-80.2710152, 43.592593801],
        [-80.2719322, 43.591546101],
        [-80.2723783, 43.591540001],
        [-80.273104, 43.590941401],
        [-80.2738654, 43.588407101],
        [-80.2753301, 43.586111301],
        [-80.2751918, 43.585227901],
        [-80.2757357, 43.583259501],
        [-80.2744101, 43.582251501],
        [-80.27462, 43.581262601],
        [-80.2743244, 43.580117101],
        [-80.2747189, 43.579228201],
        [-80.2743475, 43.578190401],
        [-80.2746256, 43.577714901],
        [-80.275678, 43.576972001],
        [-80.2757769, 43.576079601],
        [-80.2752382, 43.575495401],
        [-80.2749253, 43.573842801],
        [-80.2725856, 43.572285001],
        [-80.276901, 43.569100001],
        [-80.2765839, 43.568378501],
        [-80.2783778, 43.567090501],
        [-80.2790452, 43.568901801],
        [-80.2796845, 43.568466501],
        [-80.2799708, 43.568668601],
        [-80.2885015, 43.562401601],
        [-80.2944937, 43.563436801],
        [-80.3036072, 43.556733101],
        [-80.3017386, 43.555374001],
        [-80.3196031, 43.542260301],
        [-80.3178676, 43.541000501],
        [-80.3269091, 43.534516601],
      ],
    ],
    lat: 43.5448,
    long: -80.2482,
  },
  {
    name: "Ajax",
    coordinates: [
      [
        [-79.0882994, 43.904252801],
        [-79.0879551, 43.903529901],
        [-79.0869764, 43.903739001],
        [-79.0826016, 43.892712301],
        [-79.0773731, 43.894695501],
        [-79.075213, 43.889702701],
        [-79.0746856, 43.887563601],
        [-79.0698243, 43.875988901],
        [-79.0787863, 43.870688001],
        [-79.0726101, 43.859072301],
        [-79.0706133, 43.854334201],
        [-79.0697944, 43.855329101],
        [-79.0678787, 43.854735001],
        [-79.0665164, 43.854997501],
        [-79.0608723, 43.842029601],
        [-79.0559085, 43.844324701],
        [-79.0515501, 43.845488401],
        [-79.0480993, 43.837380101],
        [-79.047763, 43.837446601],
        [-79.0474138, 43.836971201],
        [-79.0402577, 43.819784101],
        [-79.0351812, 43.805831901],
        [-78.9645592, 43.817374401],
        [-78.9704614, 43.832756401],
        [-79.0085864, 43.922625001],
        [-79.0676033, 43.908926601],
        [-79.0766554, 43.907114201],
        [-79.078062, 43.906550801],
        [-79.0882994, 43.904252801],
      ],
    ],
    lat: 43.8509,
    long: -79.0204,
  },
  {
    name: "Brant",
    issue: "Is same as brantFord",
    coordinates: [],
    lat: 43.1313,
    long: -80.3443,
  },
  {
    name: "Brantford",
    issue: "There is two brantford",
    coordinates: [],
    lat: 43.1394,
    long: -80.2644,
  },
  {
    name: "Burlington",
    coordinates: [
      [
        [-79.9592898, 43.417272301],
        [-79.9102668, 43.382909701],
        [-79.8606343, 43.347446001],
        [-79.8601734, 43.347366601],
        [-79.8598324, 43.346922801],
        [-79.859594, 43.345212801],
        [-79.8606946, 43.343385301],
        [-79.8606075, 43.342762501],
        [-79.8612411, 43.341406801],
        [-79.8629784, 43.340238101],
        [-79.8639798, 43.339025401],
        [-79.8654515, 43.337983701],
        [-79.8657062, 43.336796901],
        [-79.8666007, 43.336414401],
        [-79.866726, 43.335825001],
        [-79.8674049, 43.335664601],
        [-79.8697612, 43.334392101],
        [-79.8736067, 43.330625101],
        [-79.8763952, 43.329035401],
        [-79.8803328, 43.328095201],
        [-79.8834734, 43.329820001],
        [-79.9124929, 43.308423401],
        [-79.8910561, 43.293142101],
        [-79.8873441, 43.290195901],
        [-79.8872754, 43.289664801],
        [-79.8875048, 43.289467101],
        [-79.8860523, 43.289056401],
        [-79.8862538, 43.288085601],
        [-79.8860756, 43.287878401],
        [-79.8849258, 43.287945601],
        [-79.8838861, 43.288305701],
        [-79.8811055, 43.287971701],
        [-79.8772943, 43.287958201],
        [-79.87564, 43.288526401],
        [-79.8751572, 43.288137901],
        [-79.8754499, 43.287848701],
        [-79.8739172, 43.286852301],
        [-79.8716169, 43.287420801],
        [-79.8699261, 43.287008501],
        [-79.8683918, 43.286284501],
        [-79.868057, 43.286409501],
        [-79.867895, 43.287317001],
        [-79.8672309, 43.287748901],
        [-79.8666215, 43.287798901],
        [-79.8652997, 43.288848501],
        [-79.8640294, 43.290354201],
        [-79.858112, 43.293891001],
        [-79.857429, 43.294789801],
        [-79.8542018, 43.296895001],
        [-79.8508318, 43.298468701],
        [-79.850631, 43.298329101],
        [-79.8506572, 43.298555501],
        [-79.846464, 43.300510901],
        [-79.8447939, 43.298979001],
        [-79.8443182, 43.298951901],
        [-79.8454301, 43.299933501],
        [-79.8452092, 43.300185801],
        [-79.8448222, 43.299960301],
        [-79.8443757, 43.300311701],
        [-79.8452423, 43.301076901],
        [-79.8432628, 43.302556301],
        [-79.8419075, 43.302648801],
        [-79.8420214, 43.302793601],
        [-79.8411613, 43.303080401],
        [-79.8408538, 43.302943501],
        [-79.8406773, 43.303231901],
        [-79.8402799, 43.303159601],
        [-79.8390851, 43.303734701],
        [-79.8389772, 43.304228901],
        [-79.8396036, 43.304339301],
        [-79.8385772, 43.304522701],
        [-79.8385687, 43.304984901],
        [-79.8378391, 43.304916201],
        [-79.8378906, 43.305109801],
        [-79.8372726, 43.304897401],
        [-79.8365946, 43.305490801],
        [-79.8354788, 43.305696901],
        [-79.8348865, 43.306177901],
        [-79.8344316, 43.306015501],
        [-79.8338308, 43.306215301],
        [-79.8334875, 43.306096701],
        [-79.8333502, 43.306409001],
        [-79.831704, 43.307260801],
        [-79.8289041, 43.306996101],
        [-79.8282751, 43.307273201],
        [-79.828199, 43.308278501],
        [-79.8276313, 43.308285001],
        [-79.8275735, 43.307932701],
        [-79.8271618, 43.307901701],
        [-79.8247359, 43.309388401],
        [-79.8220639, 43.310197301],
        [-79.8215167, 43.310711101],
        [-79.8181111, 43.311420401],
        [-79.8165404, 43.312219801],
        [-79.8122832, 43.313369001],
        [-79.8093736, 43.313044201],
        [-79.807142, 43.312432201],
        [-79.8046944, 43.310708101],
        [-79.804077, 43.308224001],
        [-79.8036472, 43.308097501],
        [-79.8022396, 43.306017601],
        [-79.8023834, 43.305292101],
        [-79.8016817, 43.304487401],
        [-79.8020033, 43.304069901],
        [-79.8011143, 43.303693001],
        [-79.8007115, 43.302526301],
        [-79.800886, 43.301967101],
        [-79.8035788, 43.300833501],
        [-79.8034501, 43.300340001],
        [-79.8043084, 43.300002701],
        [-79.8034844, 43.300265101],
        [-79.8031754, 43.299834101],
        [-79.8035703, 43.299659201],
        [-79.8017133, 43.296976101],
        [-79.7984719, 43.298478501],
        [-79.798223, 43.298228701],
        [-79.799459, 43.297491601],
        [-79.7909895, 43.301986001],
        [-79.713939, 43.364854101],
        [-79.7247985, 43.371989501],
        [-79.748774, 43.389665201],
        [-79.8109532, 43.434763601],
        [-79.8198972, 43.426388601],
        [-79.8291578, 43.432602101],
        [-79.837718, 43.438787701],
        [-79.8396502, 43.439740401],
        [-79.8407494, 43.440901901],
        [-79.8422516, 43.440406001],
        [-79.8425812, 43.440475601],
        [-79.8679864, 43.457802201],
        [-79.8947465, 43.476553301],
        [-79.8987215, 43.472806101],
        [-79.9058631, 43.466704901],
        [-79.9188494, 43.454577701],
        [-79.9215903, 43.451630001],
        [-79.9428521, 43.432544701],
        [-79.9437345, 43.431150101],
        [-79.9451319, 43.429798301],
        [-79.9461619, 43.428155201],
        [-79.9480958, 43.427161801],
        [-79.9592898, 43.417272301],
      ],
    ],
    lat: 43.3255,
    long: -79.799,
  },
  {
    name: "Newmarket",
    coordinates: [
      [
        [-79.5093678, 44.063574301],
        [-79.5086306, 44.061562901],
        [-79.508282, 44.057769601],
        [-79.5067102, 44.051601201],
        [-79.4993582, 44.016056501],
        [-79.4545081, 44.025784901],
        [-79.4117446, 44.035474401],
        [-79.4163467, 44.049165201],
        [-79.4170978, 44.052110801],
        [-79.4214537, 44.073511601],
        [-79.4214537, 44.077643001],
        [-79.4225909, 44.083454201],
        [-79.5093678, 44.063574301],
      ],
    ],
    lat: 44.0501,
    long: -79.4663,
  },
  {
    name: "Thorold",
    coordinates: [
      [
        [-79.2871604, 43.108645501],
        [-79.2857555, 43.059174101],
        [-79.2845611, 43.056689001],
        [-79.2837221, 43.055402201],
        [-79.2826307, 43.054332201],
        [-79.2811733, 43.053369401],
        [-79.2791901, 43.052745201],
        [-79.2752685, 43.052037901],
        [-79.2732772, 43.052007801],
        [-79.2717281, 43.052367201],
        [-79.2678726, 43.054123801],
        [-79.2673998, 43.026873101],
        [-79.2497457, 43.026800001],
        [-79.2496919, 43.031410001],
        [-79.2446383, 43.031375201],
        [-79.2446531, 43.026769201],
        [-79.2219088, 43.026686201],
        [-79.2200968, 43.027228801],
        [-79.218233, 43.028277501],
        [-79.2169824, 43.028480201],
        [-79.2054065, 43.028743501],
        [-79.2039452, 43.029738501],
        [-79.2035046, 43.030745101],
        [-79.2032084, 43.035588401],
        [-79.2025418, 43.038959601],
        [-79.2015939, 43.040269301],
        [-79.2009937, 43.040627501],
        [-79.1994907, 43.040852701],
        [-79.1977764, 43.040511601],
        [-79.1920441, 43.038340201],
        [-79.1874133, 43.036311401],
        [-79.1843426, 43.035754801],
        [-79.1825021, 43.036141501],
        [-79.1797624, 43.037573801],
        [-79.1784479, 43.038618301],
        [-79.17665, 43.040994501],
        [-79.174722, 43.042413501],
        [-79.1727273, 43.042677701],
        [-79.1685805, 43.041477301],
        [-79.1687149, 43.058759001],
        [-79.1748962, 43.058876501],
        [-79.175405, 43.108709801],
        [-79.1772787, 43.140595401],
        [-79.2084242, 43.129921001],
        [-79.2184299, 43.125967701],
        [-79.2483145, 43.115366301],
        [-79.2533964, 43.113363601],
        [-79.2637606, 43.109938001],
        [-79.2694874, 43.108437301],
        [-79.2746463, 43.106662001],
        [-79.2746373, 43.108790901],
        [-79.2871604, 43.108645501],
      ],
    ],
    lat: 43.1168,
    long: -79.1982,
  },
  {
    name: "Welland",
    coordinates: [
      [
        [-79.2908147, 42.966224501],
        [-79.2865553, 42.969727601],
        [-79.2833152, 42.971470301],
        [-79.2819527, 42.971894301],
        [-79.2813348, 42.945243701],
        [-79.249021, 42.945367801],
        [-79.2421546, 42.919286901],
        [-79.2377343, 42.934967301],
        [-79.2350306, 42.942507501],
        [-79.2283788, 42.959122501],
        [-79.167167, 42.959288001],
        [-79.1671801, 42.961951401],
        [-79.1691113, 42.961951401],
        [-79.1697606, 43.003157201],
        [-79.1694308, 43.007432001],
        [-79.1695376, 43.012725401],
        [-79.1687525, 43.012729501],
        [-79.1690443, 43.041553701],
        [-79.1727273, 43.042677701],
        [-79.1748104, 43.042380301],
        [-79.17665, 43.040994501],
        [-79.1784479, 43.038618301],
        [-79.1797624, 43.037573801],
        [-79.1825021, 43.036141501],
        [-79.1843426, 43.035754801],
        [-79.1874133, 43.036311401],
        [-79.1920441, 43.038340201],
        [-79.1977764, 43.040511601],
        [-79.1994907, 43.040852701],
        [-79.2009937, 43.040627501],
        [-79.2015939, 43.040269301],
        [-79.2025418, 43.038959601],
        [-79.2032084, 43.035588401],
        [-79.2035046, 43.030745101],
        [-79.2039452, 43.029738501],
        [-79.2054065, 43.028743501],
        [-79.2169824, 43.028480201],
        [-79.218233, 43.028277501],
        [-79.2200968, 43.027228801],
        [-79.2219088, 43.026686201],
        [-79.2446531, 43.026769201],
        [-79.2446383, 43.031375201],
        [-79.2496919, 43.031410001],
        [-79.2497457, 43.026800001],
        [-79.2750387, 43.026747601],
        [-79.2750397, 43.027636101],
        [-79.2775878, 43.027624901],
        [-79.2775806, 43.026814301],
        [-79.2800631, 43.026834801],
        [-79.2802648, 43.017367401],
        [-79.2905411, 43.017404301],
        [-79.2906383, 43.006658501],
        [-79.2900745, 43.006662101],
        [-79.2899255, 42.997590301],
        [-79.2905917, 42.997582001],
        [-79.2908147, 42.966224501],
      ],
    ],
    lat: 42.9927,
    long: -79.2483,
  },
  {
    name: "Waterloo",
    coordinates: [
      [
        [-80.6260904, 43.479650801],
        [-80.6065153, 43.462257001],
        [-80.5734577, 43.432266301],
        [-80.5471936, 43.441160201],
        [-80.546024, 43.442032901],
        [-80.5449006, 43.444115901],
        [-80.5428299, 43.443071701],
        [-80.5409218, 43.443612001],
        [-80.5424188, 43.445553201],
        [-80.5386374, 43.446898201],
        [-80.5409991, 43.449005201],
        [-80.5343283, 43.451380701],
        [-80.5338412, 43.450476501],
        [-80.5265861, 43.453061501],
        [-80.5272017, 43.453715501],
        [-80.5269821, 43.453870101],
        [-80.5214506, 43.455053901],
        [-80.5193947, 43.456902101],
        [-80.5191683, 43.456711901],
        [-80.5182212, 43.456894001],
        [-80.5179132, 43.457176601],
        [-80.5156553, 43.457931301],
        [-80.5146612, 43.458648801],
        [-80.5136935, 43.458388201],
        [-80.5129472, 43.459047301],
        [-80.5122702, 43.458850501],
        [-80.5115308, 43.459285501],
        [-80.5065552, 43.460910501],
        [-80.5046927, 43.461821701],
        [-80.5008102, 43.463161801],
        [-80.5011281, 43.463453501],
        [-80.5006187, 43.463631401],
        [-80.5017087, 43.464636901],
        [-80.4984476, 43.466352901],
        [-80.5009362, 43.468643901],
        [-80.4955187, 43.470560201],
        [-80.4964133, 43.471332101],
        [-80.4953594, 43.471613301],
        [-80.4966488, 43.472811401],
        [-80.4914451, 43.474496201],
        [-80.4923553, 43.475978501],
        [-80.4933267, 43.476294101],
        [-80.4950099, 43.476338901],
        [-80.4957919, 43.476795801],
        [-80.497949, 43.480765101],
        [-80.4975466, 43.480906601],
        [-80.4973035, 43.480682901],
        [-80.4960609, 43.481112901],
        [-80.4954343, 43.481075101],
        [-80.4942781, 43.480427301],
        [-80.4918723, 43.481245501],
        [-80.486569, 43.481481101],
        [-80.4881824, 43.482667201],
        [-80.4878241, 43.482944501],
        [-80.4888306, 43.483977601],
        [-80.4884822, 43.484228201],
        [-80.4889974, 43.484725701],
        [-80.4887547, 43.484820801],
        [-80.4873977, 43.484018701],
        [-80.4841578, 43.485286601],
        [-80.4810507, 43.489234301],
        [-80.4809004, 43.491021801],
        [-80.4814418, 43.498751801],
        [-80.4809664, 43.500108201],
        [-80.4775232, 43.500949901],
        [-80.4794295, 43.504071901],
        [-80.484388, 43.502682201],
        [-80.4889806, 43.501803401],
        [-80.4910339, 43.501841501],
        [-80.4923523, 43.502369801],
        [-80.4933439, 43.503640801],
        [-80.4948863, 43.507870401],
        [-80.4952885, 43.509896301],
        [-80.4951264, 43.511389001],
        [-80.4940409, 43.512295601],
        [-80.4928395, 43.512651801],
        [-80.4880504, 43.513106501],
        [-80.4870327, 43.513536901],
        [-80.4848335, 43.515556301],
        [-80.4828781, 43.516290901],
        [-80.4798151, 43.515991301],
        [-80.4714131, 43.516256601],
        [-80.4703463, 43.516487301],
        [-80.4691645, 43.516915101],
        [-80.4681503, 43.517599801],
        [-80.4674214, 43.519012501],
        [-80.4675315, 43.520135501],
        [-80.4688206, 43.521697501],
        [-80.4707071, 43.523013901],
        [-80.475529, 43.524516901],
        [-80.4771272, 43.525598201],
        [-80.4790622, 43.527527501],
        [-80.4802474, 43.529302601],
        [-80.4806847, 43.531220901],
        [-80.6260904, 43.479650801],
      ],
    ],
    lat: 43.4643,
    long: -80.5204,
  },
  {
    name: "Thornhill",
    issue: "Vaughan or Markham",
    coordinates: [],
    lat: 43.8113,
    long: -79.4245,
  },
  {
    name: "Niagara Falls",
    coordinates: [
      [
        [-79.1772787, 43.140595401],
        [-79.175405, 43.108709801],
        [-79.1748962, 43.058876501],
        [-79.1687149, 43.058759001],
        [-79.1685805, 43.041477301],
        [-79.1690443, 43.041553701],
        [-79.1687525, 43.012729501],
        [-79.1695376, 43.012725401],
        [-79.1694308, 43.007432001],
        [-79.1697606, 43.003157201],
        [-79.1691113, 42.961951401],
        [-79.1671801, 42.961951401],
        [-79.167167, 42.959288001],
        [-79.1153172, 42.959438201],
        [-79.1151311, 42.963239301],
        [-79.1201797, 42.963198501],
        [-79.122475, 42.962850101],
        [-79.1225511, 42.967467501],
        [-79.0919924, 42.967237901],
        [-79.0295079, 42.968075101],
        [-79.0276196, 42.971403601],
        [-79.0286989, 42.981454201],
        [-79.0116642, 42.985288601],
        [-79.0201819, 42.994821901],
        [-79.0234241, 43.016288101],
        [-79.0118248, 43.029159201],
        [-79.0052984, 43.047243001],
        [-78.9996403, 43.055989701],
        [-79.0073964, 43.065916701],
        [-79.0740736, 43.077847801],
        [-79.0753528, 43.081616101],
        [-79.0697664, 43.088538301],
        [-79.0661939, 43.091340001],
        [-79.0578728, 43.107095901],
        [-79.0586475, 43.110920301],
        [-79.060105, 43.113866401],
        [-79.0625633, 43.116091701],
        [-79.0696492, 43.120321901],
        [-79.0594087, 43.126476401],
        [-79.0570875, 43.127036901],
        [-79.0541228, 43.129368601],
        [-79.0525481, 43.131639401],
        [-79.04949, 43.135148901],
        [-79.0436731, 43.138406401],
        [-79.0422989, 43.143663301],
        [-79.0426981, 43.147928801],
        [-79.121233, 43.146709701],
        [-79.1336965, 43.146315301],
        [-79.1543544, 43.145279001],
        [-79.1568291, 43.145003701],
        [-79.1569589, 43.147759801],
        [-79.1772787, 43.140595401],
      ],
    ],
    lat: 43.0896,
    long: -79.0849,
  },
  {
    name: "Kitchener",
    coordinates: [
      [
        [-80.5734577, 43.432266301],
        [-80.5164311, 43.380356301],
        [-80.5058325, 43.353875601],
        [-80.413078, 43.369954101],
        [-80.4044559, 43.385089901],
        [-80.402391, 43.387633901],
        [-80.3996938, 43.389840701],
        [-80.3981388, 43.390791501],
        [-80.3784145, 43.401712401],
        [-80.3800535, 43.405966201],
        [-80.3805965, 43.405875401],
        [-80.382433, 43.410667301],
        [-80.3820301, 43.410665301],
        [-80.3825965, 43.411955601],
        [-80.4029522, 43.416092501],
        [-80.4045091, 43.416935101],
        [-80.408525, 43.420097701],
        [-80.4096406, 43.421902401],
        [-80.4062363, 43.422248201],
        [-80.4037923, 43.423044301],
        [-80.4027469, 43.423788301],
        [-80.4022934, 43.424455801],
        [-80.4019801, 43.426919901],
        [-80.402605, 43.429064001],
        [-80.4062869, 43.431747801],
        [-80.4091852, 43.433470601],
        [-80.4102747, 43.435664901],
        [-80.4098963, 43.437273401],
        [-80.409344, 43.438025901],
        [-80.4074297, 43.439546501],
        [-80.4033766, 43.441598601],
        [-80.4013217, 43.443548401],
        [-80.3960129, 43.446094901],
        [-80.3940643, 43.447151201],
        [-80.3932262, 43.447859101],
        [-80.3924523, 43.449288401],
        [-80.3920013, 43.450994401],
        [-80.392215, 43.452118701],
        [-80.3933536, 43.453692901],
        [-80.3946729, 43.454847401],
        [-80.3963908, 43.455481601],
        [-80.3981566, 43.455829901],
        [-80.4035477, 43.456852001],
        [-80.4108638, 43.456932401],
        [-80.4140267, 43.457757101],
        [-80.4159089, 43.458476601],
        [-80.4171723, 43.459638001],
        [-80.4201119, 43.461125801],
        [-80.4212803, 43.463805301],
        [-80.423188, 43.466601701],
        [-80.4237033, 43.467297001],
        [-80.4261321, 43.468951601],
        [-80.4272645, 43.471320101],
        [-80.4266477, 43.472745301],
        [-80.4246019, 43.474178301],
        [-80.4224397, 43.477158301],
        [-80.422275, 43.478704401],
        [-80.422473, 43.479752201],
        [-80.4228347, 43.480230001],
        [-80.4240701, 43.480982201],
        [-80.4254436, 43.481465101],
        [-80.4280278, 43.481600101],
        [-80.4305191, 43.482242801],
        [-80.4331638, 43.482097401],
        [-80.4368657, 43.480649901],
        [-80.4379964, 43.479765301],
        [-80.4405218, 43.478666301],
        [-80.4434061, 43.478033201],
        [-80.4461182, 43.476927801],
        [-80.4480731, 43.476664101],
        [-80.4497186, 43.476835301],
        [-80.4513709, 43.477335201],
        [-80.4551364, 43.477265101],
        [-80.4567504, 43.479616101],
        [-80.4581598, 43.482808001],
        [-80.4600965, 43.484064701],
        [-80.4627201, 43.485219801],
        [-80.4647989, 43.484500201],
        [-80.4654748, 43.485530301],
        [-80.4677581, 43.484599301],
        [-80.4680526, 43.485043801],
        [-80.4682579, 43.484972301],
        [-80.4697296, 43.487185001],
        [-80.470879, 43.486787401],
        [-80.4709373, 43.487373401],
        [-80.4714705, 43.488096801],
        [-80.4726795, 43.488997501],
        [-80.4731322, 43.489982301],
        [-80.4733242, 43.491687001],
        [-80.4726843, 43.493443101],
        [-80.4699933, 43.494707401],
        [-80.4658606, 43.498240601],
        [-80.4644234, 43.499976701],
        [-80.464853, 43.502917701],
        [-80.4659481, 43.505137001],
        [-80.4672841, 43.506190701],
        [-80.4691392, 43.506671601],
        [-80.4716484, 43.506828201],
        [-80.4725451, 43.506419901],
        [-80.4736901, 43.505355301],
        [-80.4758394, 43.504711201],
        [-80.4794295, 43.504071901],
        [-80.4775232, 43.500949901],
        [-80.4809664, 43.500108201],
        [-80.4814418, 43.498751801],
        [-80.4809004, 43.491021801],
        [-80.4810507, 43.489234301],
        [-80.4841578, 43.485286601],
        [-80.4873977, 43.484018701],
        [-80.4887547, 43.484820801],
        [-80.4889974, 43.484725701],
        [-80.4884822, 43.484228201],
        [-80.4888306, 43.483977601],
        [-80.4878241, 43.482944501],
        [-80.4881824, 43.482667201],
        [-80.486569, 43.481481101],
        [-80.4918723, 43.481245501],
        [-80.4942781, 43.480427301],
        [-80.4954343, 43.481075101],
        [-80.4960609, 43.481112901],
        [-80.4973035, 43.480682901],
        [-80.4975466, 43.480906601],
        [-80.497949, 43.480765101],
        [-80.4957919, 43.476795801],
        [-80.4950099, 43.476338901],
        [-80.4933267, 43.476294101],
        [-80.4923553, 43.475978501],
        [-80.4914451, 43.474496201],
        [-80.4966488, 43.472811401],
        [-80.4953594, 43.471613301],
        [-80.4964133, 43.471332101],
        [-80.4955187, 43.470560201],
        [-80.5009362, 43.468643901],
        [-80.4984476, 43.466352901],
        [-80.5017087, 43.464636901],
        [-80.5006187, 43.463631401],
        [-80.5011281, 43.463453501],
        [-80.5008102, 43.463161801],
        [-80.5046927, 43.461821701],
        [-80.5065552, 43.460910501],
        [-80.5115308, 43.459285501],
        [-80.5122702, 43.458850501],
        [-80.5129472, 43.459047301],
        [-80.5136935, 43.458388201],
        [-80.5146612, 43.458648801],
        [-80.5156553, 43.457931301],
        [-80.5179132, 43.457176601],
        [-80.5182212, 43.456894001],
        [-80.5191683, 43.456711901],
        [-80.5193947, 43.456902101],
        [-80.5214506, 43.455053901],
        [-80.5269821, 43.453870101],
        [-80.5272017, 43.453715501],
        [-80.5265861, 43.453061501],
        [-80.5338412, 43.450476501],
        [-80.5343283, 43.451380701],
        [-80.5409991, 43.449005201],
        [-80.5386374, 43.446898201],
        [-80.5424188, 43.445553201],
        [-80.5409218, 43.443612001],
        [-80.5428299, 43.443071701],
        [-80.5449006, 43.444115901],
        [-80.546024, 43.442032901],
        [-80.5471936, 43.441160201],
        [-80.5734577, 43.432266301],
      ],
    ],
    lat: 43.4516,
    long: -80.4925,
  },
  {
    name: "Cambridge",
    coordinates: [
      [
        [-80.413078, 43.369954101],
        [-80.3674653, 43.377997801],
        [-80.3694571, 43.383029001],
        [-80.3773699, 43.383368301],
        [-80.3777333, 43.385485901],
        [-80.3767514, 43.386456801],
        [-80.3751353, 43.387030101],
        [-80.3738539, 43.387052601],
        [-80.3737461, 43.386609701],
        [-80.3747597, 43.386133701],
        [-80.3735134, 43.386393901],
        [-80.3719117, 43.385959301],
        [-80.3691711, 43.386111701],
        [-80.3659298, 43.385699001],
        [-80.3637985, 43.384336601],
        [-80.3638711, 43.383977701],
        [-80.3632575, 43.384116301],
        [-80.3614793, 43.383433801],
        [-80.3601767, 43.383853801],
        [-80.3599365, 43.383716301],
        [-80.3601735, 43.383558401],
        [-80.3596677, 43.383640501],
        [-80.3585394, 43.384091201],
        [-80.3561, 43.384243401],
        [-80.3555845, 43.384215501],
        [-80.3558267, 43.384016301],
        [-80.3548901, 43.384114701],
        [-80.3526199, 43.384996901],
        [-80.3497328, 43.385566001],
        [-80.3468515, 43.385566801],
        [-80.3444017, 43.384686101],
        [-80.3440641, 43.384246301],
        [-80.3421119, 43.383010701],
        [-80.341538, 43.381887201],
        [-80.3411663, 43.378760601],
        [-80.3401625, 43.377389701],
        [-80.3379015, 43.376064201],
        [-80.3496085, 43.373947301],
        [-80.343778, 43.358967301],
        [-80.3452668, 43.359228701],
        [-80.3450316, 43.358657501],
        [-80.3497346, 43.357499901],
        [-80.3476286, 43.352224901],
        [-80.3423216, 43.353154701],
        [-80.341716, 43.349180401],
        [-80.3430536, 43.348959601],
        [-80.3411365, 43.341785901],
        [-80.3416458, 43.341710401],
        [-80.3402299, 43.338302001],
        [-80.3386448, 43.337921201],
        [-80.3335192, 43.334257801],
        [-80.3304051, 43.333348401],
        [-80.3280823, 43.333015301],
        [-80.3270047, 43.332484101],
        [-80.3235173, 43.332430601],
        [-80.2896787, 43.337948901],
        [-80.2849623, 43.338057501],
        [-80.274831, 43.337540501],
        [-80.2723519, 43.337770701],
        [-80.2691617, 43.338664501],
        [-80.2662815, 43.340270701],
        [-80.2642586, 43.342286001],
        [-80.2631915, 43.344262601],
        [-80.2585303, 43.342951801],
        [-80.2545648, 43.341482801],
        [-80.2544592, 43.340947901],
        [-80.2540044, 43.340991001],
        [-80.2540527, 43.341291901],
        [-80.2518492, 43.340469901],
        [-80.2514908, 43.341205601],
        [-80.2692882, 43.386215901],
        [-80.2698471, 43.388248401],
        [-80.2775951, 43.407406001],
        [-80.2814705, 43.416057401],
        [-80.2921374, 43.442734701],
        [-80.2972399, 43.454441801],
        [-80.30272, 43.468519601],
        [-80.3062805, 43.472341801],
        [-80.307715, 43.472030401],
        [-80.3148265, 43.471429201],
        [-80.3165948, 43.471047301],
        [-80.335268, 43.464521001],
        [-80.3374385, 43.463561101],
        [-80.3398514, 43.461888101],
        [-80.341452, 43.461162401],
        [-80.3532038, 43.459219901],
        [-80.3542507, 43.458604301],
        [-80.3565783, 43.456280601],
        [-80.3581274, 43.455283501],
        [-80.3729722, 43.451023501],
        [-80.3744634, 43.450186001],
        [-80.3777992, 43.447508701],
        [-80.3788912, 43.446904901],
        [-80.3874707, 43.443830701],
        [-80.3878177, 43.443918101],
        [-80.3902615, 43.447767001],
        [-80.3904956, 43.447682101],
        [-80.3921909, 43.450236601],
        [-80.3932262, 43.447859101],
        [-80.3940643, 43.447151201],
        [-80.4013217, 43.443548401],
        [-80.4033766, 43.441598601],
        [-80.4074297, 43.439546501],
        [-80.409344, 43.438025901],
        [-80.4102157, 43.436584701],
        [-80.4102747, 43.435664901],
        [-80.4091852, 43.433470601],
        [-80.4062869, 43.431747801],
        [-80.402605, 43.429064001],
        [-80.4019801, 43.426919901],
        [-80.4022934, 43.424455801],
        [-80.4027469, 43.423788301],
        [-80.4037923, 43.423044301],
        [-80.4062363, 43.422248201],
        [-80.4096406, 43.421902401],
        [-80.4087645, 43.420337701],
        [-80.4045091, 43.416935101],
        [-80.4029522, 43.416092501],
        [-80.3825965, 43.411955601],
        [-80.3820301, 43.410665301],
        [-80.382433, 43.410667301],
        [-80.3805965, 43.405875401],
        [-80.3800535, 43.405966201],
        [-80.3784145, 43.401712401],
        [-80.3981388, 43.390791501],
        [-80.3996938, 43.389840701],
        [-80.402391, 43.387633901],
        [-80.4044559, 43.385089901],
        [-80.413078, 43.369954101],
      ],
    ],
    lat: 43.3601,
    long: -80.3127,
  },
  {
    name: "Innisfil",
    coordinates: [
      [
        [-79.7279091, 44.293748101],
        [-79.723148, 44.275319301],
        [-79.7159722, 44.276952301],
        [-79.7127381, 44.265022701],
        [-79.7201008, 44.263370801],
        [-79.704303, 44.200687601],
        [-79.7100171, 44.199481401],
        [-79.7099564, 44.199242701],
        [-79.7239545, 44.196192401],
        [-79.7212778, 44.185652601],
        [-79.7219266, 44.185512501],
        [-79.7198613, 44.177286501],
        [-79.7203774, 44.177174001],
        [-79.7173542, 44.165023601],
        [-79.6887916, 44.170929501],
        [-79.6363205, 44.182246501],
        [-79.5770114, 44.195541601],
        [-79.5103613, 44.210870501],
        [-79.5081893, 44.227172601],
        [-79.5097342, 44.246604801],
        [-79.5183173, 44.262588501],
        [-79.5037261, 44.287784801],
        [-79.5035544, 44.297123101],
        [-79.5013228, 44.344529001],
        [-79.5005058, 44.399570001],
        [-79.4979995, 44.422237101],
        [-79.5664066, 44.402188501],
        [-79.6265739, 44.384095801],
        [-79.6228456, 44.368185501],
        [-79.621939, 44.368356101],
        [-79.6204172, 44.363180401],
        [-79.5907579, 44.369833401],
        [-79.5895585, 44.364773901],
        [-79.5901164, 44.362288701],
        [-79.5858974, 44.345342701],
        [-79.659275, 44.328553901],
        [-79.6544039, 44.310133401],
        [-79.7279091, 44.293748101],
      ],
    ],
    lat: 44.3001,
    long: -79.611,
  },
  {
    name: "Wasaga Beach",
    coordinates: [
      [
        [-80.1769063, 44.623041],
        [-80.1403881, 44.469978301],
        [-80.1337053, 44.467260601],
        [-80.1225815, 44.464346901],
        [-80.1212531, 44.458428401],
        [-80.1107856, 44.460584001],
        [-80.1069184, 44.458551201],
        [-80.1048735, 44.456293501],
        [-80.1040116, 44.456336001],
        [-80.092246, 44.458922701],
        [-80.0735383, 44.463540001],
        [-80.0717083, 44.456514901],
        [-80.0533621, 44.460847601],
        [-80.0511211, 44.452013001],
        [-80.0353397, 44.455618901],
        [-80.0351829, 44.455699101],
        [-80.036672, 44.457177801],
        [-80.0261425, 44.459482501],
        [-80.0264516, 44.460725001],
        [-80.019943, 44.462042201],
        [-80.0214068, 44.467747201],
        [-79.9749799, 44.478232901],
        [-79.9832046, 44.488053201],
        [-79.9656121, 44.495556901],
        [-79.9754669, 44.506817601],
        [-79.9689594, 44.509751501],
        [-79.9730352, 44.515182701],
        [-79.9739096, 44.514851501],
        [-79.9782554, 44.520290601],
        [-79.9648534, 44.526133101],
        [-79.9737983, 44.536301801],
        [-79.9788838, 44.542499901],
        [-79.9744632, 44.544384301],
        [-79.9796087, 44.550477501],
        [-79.9726731, 44.553462401],
        [-79.97747, 44.559002801],
        [-79.9771717, 44.559147201],
        [-79.9834686, 44.567333101],
        [-79.9831998, 44.568132001],
        [-79.9847919, 44.569871601],
        [-80.066571, 44.531551001],
        [-80.1769063, 44.623041],
      ],
    ],
    lat: 44.5205,
    long: -80.0164,
  },
  {
    name: "Woodstock",
    issue: "Not found on property.ca",
    coordinates: [],
    lat: 43.1315,
    long: -80.7472,
  },
  {
    name: "Oka",
    issue: "Not found anywhere",
    coordinates: [],
    lat: 45.4723,
    long: -74.086,
  },
  {
    name: "Kawartha Lakes",
    issue: "Not found on property.ca",
    coordinates: [],
    lat: 44.3573,
    long: -78.738,
  },
  {
    name: "Aurora",
    coordinates: [
      [
        [-79.4993582, 44.016056501],
        [-79.4911319, 43.979822701],
        [-79.4899061, 43.975620701],
        [-79.4893992, 43.972136601],
        [-79.4855416, 43.957468501],
        [-79.4811807, 43.958105801],
        [-79.4637331, 43.962092301],
        [-79.4497722, 43.964741101],
        [-79.4097644, 43.973368101],
        [-79.3928828, 43.977695601],
        [-79.3946122, 43.985099501],
        [-79.3960681, 43.994901801],
        [-79.396611, 43.996562801],
        [-79.3987643, 43.999690101],
        [-79.4036073, 44.004468701],
        [-79.4048078, 44.006279101],
        [-79.4061897, 44.011529001],
        [-79.4099947, 44.029439801],
        [-79.4117446, 44.035474401],
        [-79.4545081, 44.025784901],
        [-79.4993582, 44.016056501],
      ],
    ],
    lat: 44.0065,
    long: -79.4504,
  },
  {
    name: "Cobourg",
    issue: "Not found on property.ca",
    coordinates: [],
    lat: 43.9593,
    long: -78.1677,
  },
  {
    name: "Concord",
    issue: "Boundary Not found on geoapify",
    coordinates: [],
    lat: 43.7995,
    long: -79.5297,
  },
  {
    name: "East Gwillimbury",
    coordinates: [
      [
        [-79.5316668, 44.150495501],
        [-79.5108237, 44.067384301],
        [-79.5093678, 44.063574301],
        [-79.4225909, 44.083454201],
        [-79.4214537, 44.077643001],
        [-79.4214537, 44.073511601],
        [-79.4204401, 44.068371801],
        [-79.2761594, 44.101253001],
        [-79.290889, 44.136520701],
        [-79.3067665, 44.173106801],
        [-79.3280117, 44.223299801],
        [-79.5178805, 44.181570601],
        [-79.5171272, 44.177561101],
        [-79.5144664, 44.176699301],
        [-79.5139943, 44.175806701],
        [-79.5143806, 44.175191101],
        [-79.5167838, 44.173990701],
        [-79.5176421, 44.173252001],
        [-79.5176421, 44.172697901],
        [-79.5163118, 44.170604801],
        [-79.517213, 44.168203801],
        [-79.5165263, 44.166510701],
        [-79.5164524, 44.165502901],
        [-79.5179115, 44.163778901],
        [-79.523233, 44.160023001],
        [-79.5234047, 44.157621501],
        [-79.5257221, 44.155897301],
        [-79.5256363, 44.152941501],
        [-79.5260655, 44.152510401],
        [-79.5284687, 44.151586601],
        [-79.529842, 44.151401901],
        [-79.5316668, 44.150495501],
      ],
    ],
    lat: 44.117,
    long: -79.4389,
  },
  {
    name: "East York",
    issue: "On property.ca ther is difference",
    coordinates: [],
    lat: 43.6901,
    long: -79.3116,
  },
  {
    name: "Bath",
    issue: "Not found",
    coordinates: [],
    lat: 44.187,
    long: -76.7711,
  },
  {
    name: "Belleville",
    coordinates: [
      [
        [
          [-77.4735333, 44.315539201],
          [-77.4519691, 44.270940501],
          [-77.4366722, 44.240169101],
          [-77.4229817, 44.211247601],
          [-77.4115665, 44.188690601],
          [-77.4471401, 44.179741001],
          [-77.4386519, 44.162500301],
          [-77.4349961, 44.155690401],
          [-77.4232496, 44.130771901],
          [-77.4079176, 44.135723401],
          [-77.4002195, 44.140076001],
          [-77.3927551, 44.144754401],
          [-77.3870793, 44.147078701],
          [-77.3854896, 44.147229901],
          [-77.380105, 44.146884401],
          [-77.3685301, 44.148120101],
          [-77.3629446, 44.149317801],
          [-77.3504773, 44.152705501],
          [-77.3424768, 44.153962101],
          [-77.338056, 44.154187801],
          [-77.3311412, 44.154201001],
          [-77.315558, 44.151050101],
          [-77.2406365, 44.150334701],
          [-77.2356166, 44.149928701],
          [-77.2465509, 44.172117101],
          [-77.2474425, 44.171587401],
          [-77.2483008, 44.171462501],
          [-77.2488164, 44.171895701],
          [-77.2492603, 44.171695701],
          [-77.2497043, 44.171858001],
          [-77.2492089, 44.172975101],
          [-77.2499075, 44.173582601],
          [-77.2498712, 44.174600601],
          [-77.2513634, 44.175354501],
          [-77.251654, 44.176744201],
          [-77.2503635, 44.176503001],
          [-77.2487655, 44.176580101],
          [-77.280152, 44.239762101],
          [-77.3067469, 44.295599601],
          [-77.3332727, 44.348788101],
          [-77.3591476, 44.342323901],
          [-77.4311482, 44.325752501],
          [-77.4735333, 44.315539201],
        ],
      ],
    ],
    lat: 44.1628,
    long: -77.3832,
  },
  {
    name: "Halton",
    issue: "Not Found on property.ca",
    coordinates: [],
    lat: 43.4202,
    long: -79.8159,
  },
  {
    name: "London",
    issue:
      "On property.cs there is bristol london and on geoapify it not give coordinates",
    coordinates: [],
    lat: 42.9849,
    long: -81.2453,
  },
  {
    name: "Orillia",
    coordinates: [
      [
        [
          [-79.4756963, 44.603311101],
          [-79.4470659, 44.569994801],
          [-79.4468674, 44.569954601],
          [-79.4469479, 44.571168001],
          [-79.4465563, 44.571959001],
          [-79.4441441, 44.573815701],
          [-79.439954, 44.576728001],
          [-79.4345732, 44.578998401],
          [-79.4245912, 44.567165501],
          [-79.4173562, 44.570073801],
          [-79.4180159, 44.570270001],
          [-79.4190383, 44.570040101],
          [-79.4201381, 44.570277501],
          [-79.4215314, 44.570030701],
          [-79.4234003, 44.571347201],
          [-79.4237388, 44.571798901],
          [-79.4239636, 44.573085401],
          [-79.4232931, 44.576459401],
          [-79.4213734, 44.579364601],
          [-79.4201125, 44.580786101],
          [-79.419744, 44.580929601],
          [-79.4187465, 44.580505701],
          [-79.4195104, 44.581051601],
          [-79.4194565, 44.581460501],
          [-79.4178239, 44.582670101],
          [-79.4173896, 44.583329501],
          [-79.4169043, 44.583442901],
          [-79.4164399, 44.584551901],
          [-79.4145825, 44.585821401],
          [-79.4146094, 44.586217501],
          [-79.4135338, 44.586998901],
          [-79.4116673, 44.587930101],
          [-79.3970509, 44.570541901],
          [-79.3789347, 44.579364401],
          [-79.3770635, 44.584021201],
          [-79.3749671, 44.590390701],
          [-79.3700898, 44.600084101],
          [-79.3692992, 44.607902301],
          [-79.3698387, 44.608640901],
          [-79.369636, 44.617441601],
          [-79.3703451, 44.618542001],
          [-79.3897547, 44.6315918],
          [-79.4009717, 44.6355069],
          [-79.4032151, 44.6396149],
          [-79.4175623, 44.6387751],
          [-79.4176709, 44.6394534],
          [-79.4170594, 44.6421078],
          [-79.4193813, 44.6409896],
          [-79.4206299, 44.6411041],
          [-79.4204786, 44.644182],
          [-79.421176, 44.6441854],
          [-79.4406045, 44.6217437],
          [-79.4423316, 44.623954],
          [-79.44981, 44.6209643],
          [-79.4460103, 44.616481501],
          [-79.461312, 44.610409501],
          [-79.4608686, 44.609909901],
          [-79.4676349, 44.607078301],
          [-79.4693463, 44.605766901],
          [-79.4756963, 44.603311101],
        ],
      ],
    ],
    lat: 44.6082,
    long: -79.4192,
  },
  {
    name: "Prince Edward",
    issue: "Not found on Property.ca",
    coordinates: [],
    lat: 44.0001,
    long: -77.2503,
  },
  {
    name: "Russel",
    coordinates: [
      [
        [
          [-75.4259397, 45.312479],
          [-75.4108639, 45.2817784],
          [-75.4010672, 45.2628016],
          [-75.3815785, 45.2285842],
          [-75.3688168, 45.206882],
          [-75.3651917, 45.2007977],
          [-75.3531787, 45.1820935],
          [-75.319972, 45.1950037],
          [-75.3193268, 45.1957256],
          [-75.3176219, 45.1959194],
          [-75.3104601, 45.1986273],
          [-75.3084581, 45.198789],
          [-75.3079111, 45.1996503],
          [-75.262704, 45.2168404],
          [-75.201154, 45.239797],
          [-75.2067696, 45.251686],
          [-75.2342798, 45.3005098],
          [-75.2494123, 45.3259271],
          [-75.2681762, 45.3561295],
          [-75.3300494, 45.3382041],
          [-75.3763164, 45.3257333],
          [-75.4070694, 45.3177669],
          [-75.4259397, 45.312479],
        ],
      ],
    ],
    lat: 45.259,
    long: -75.3586,
  },
  {
    name: "Shelburne",
    issue: "Not found on Property.ca",
    coordinates: [
      [
        [
          [-80.2234583, 44.086375101],
          [-80.2208981, 44.075071001],
          [-80.21456, 44.076327901],
          [-80.213536, 44.071808901],
          [-80.2049653, 44.073542401],
          [-80.2037037, 44.067882801],
          [-80.1777553, 44.073190601],
          [-80.1786053, 44.076937901],
          [-80.1828865, 44.076106701],
          [-80.1845619, 44.082321001],
          [-80.1799036, 44.083266301],
          [-80.1812202, 44.088758401],
          [-80.189448, 44.087088301],
          [-80.1906788, 44.092499501],
          [-80.2072071, 44.089180001],
          [-80.2079621, 44.090873601],
          [-80.2098303, 44.092237101],
          [-80.2111989, 44.092651801],
          [-80.2166149, 44.093471401],
          [-80.2151732, 44.086984701],
          [-80.2220698, 44.085631901],
          [-80.2234583, 44.086375101],
        ],
      ],
    ],
    lat: 44.0782,
    long: -80.2047,
  },
  {
    name: "Simcoe",
    issue: "Boundaries not found on geoApify",
    coordinates: [],
    lat: 42.8372,
    long: -80.3047,
  },
  {
    name: "Cochrane",
    issue: "Not found on Property.ca",
    coordinates: [
      [
        [
          [-81.2668305, 49.3049736],
          [-81.2661514, 49.3042031],
          [-81.2650681, 49.3044396],
          [-81.2640382, 49.3054429],
          [-81.262909, 49.3048667],
          [-81.2616044, 49.3046799],
          [-81.2611466, 49.3048897],
          [-81.2609636, 49.3056449],
          [-81.2599945, 49.3060264],
          [-81.2596589, 49.3064498],
          [-81.2585909, 49.3058663],
          [-81.2584686, 49.3047599],
          [-81.2589416, 49.3042336],
          [-81.2590257, 49.303566],
          [-81.2586213, 49.3024979],
          [-81.258194, 49.3023642],
          [-81.2577669, 49.3027686],
          [-81.2571564, 49.3017006],
          [-81.2575379, 49.3003577],
          [-81.2566681, 49.2998047],
          [-81.2561339, 49.2984619],
          [-81.255669, 49.2982941],
          [-81.2553177, 49.2977144],
          [-81.2545166, 49.2971992],
          [-81.2534638, 49.2973366],
          [-81.2534638, 49.2971038],
          [-81.253952, 49.2969094],
          [-81.253853, 49.2967454],
          [-81.2523269, 49.2961731],
          [-81.2539978, 49.2945211],
          [-81.2549897, 49.2948953],
          [-81.2562561, 49.294979],
          [-81.2566223, 49.2937547],
          [-81.2559051, 49.2918357],
          [-81.2568588, 49.2916108],
          [-81.2568284, 49.2912979],
          [-81.2554627, 49.2900162],
          [-81.2548066, 49.2890548],
          [-81.2540207, 49.2888756],
          [-81.2531357, 49.2894363],
          [-81.2527162, 49.289299],
          [-81.2530213, 49.2890587],
          [-81.2529603, 49.2885361],
          [-81.2518311, 49.2877388],
          [-81.2509462, 49.2875824],
          [-81.2506484, 49.2879982],
          [-81.2503052, 49.2879449],
          [-81.249794, 49.2883416],
          [-81.2501526, 49.2876662],
          [-81.2495421, 49.2872583],
          [-81.2500381, 49.2868958],
          [-81.249443, 49.2861634],
          [-81.2503357, 49.2864532],
          [-81.2508623, 49.2863807],
          [-81.2501679, 49.2857437],
          [-81.2504045, 49.2853623],
          [-81.2489243, 49.2846641],
          [-81.248886, 49.2839965],
          [-81.2477645, 49.2833291],
          [-81.2474592, 49.2828941],
          [-81.2461396, 49.2826424],
          [-81.2459488, 49.2832185],
          [-81.2449342, 49.2834779],
          [-81.2442322, 49.284069],
          [-81.2440415, 49.283676],
          [-81.2436601, 49.2836876],
          [-81.2432937, 49.2831991],
          [-81.2435838, 49.2830543],
          [-81.2445603, 49.2832338],
          [-81.2450715, 49.2830582],
          [-81.2452851, 49.2828331],
          [-81.2450791, 49.2822914],
          [-81.245613, 49.2823791],
          [-81.2459565, 49.2820665],
          [-81.2474441, 49.2816543],
          [-81.2467499, 49.2815514],
          [-81.2471466, 49.2811317],
          [-81.2468415, 49.280285],
          [-81.2469025, 49.2787017],
          [-81.2461547, 49.2778893],
          [-81.2450945, 49.2773552],
          [-81.2461396, 49.2772257],
          [-81.246109, 49.2768593],
          [-81.2451249, 49.2752953],
          [-81.2441102, 49.2747841],
          [-81.2445451, 49.2744369],
          [-81.2438431, 49.2742348],
          [-81.2436524, 49.2738838],
          [-81.243057, 49.2736664],
          [-81.2420274, 49.2739831],
          [-81.2421494, 49.2736892],
          [-81.2418136, 49.2733802],
          [-81.2413406, 49.2736093],
          [-81.2399749, 49.2729873],
          [-81.2395019, 49.272358],
          [-81.2406461, 49.2723961],
          [-81.2406388, 49.2718277],
          [-81.2393875, 49.2715874],
          [-81.2374725, 49.270874],
          [-81.2363511, 49.2710915],
          [-81.2345888, 49.2706642],
          [-81.23378, 49.2707862],
          [-81.2341919, 49.2710494],
          [-81.2357483, 49.2712785],
          [-81.2362137, 49.2715376],
          [-81.2356644, 49.2716828],
          [-81.2350311, 49.2715339],
          [-81.2348023, 49.271801],
          [-81.2323913, 49.2714501],
          [-81.2295837, 49.2706452],
          [-81.2288896, 49.2698632],
          [-81.2309112, 49.2699204],
          [-81.2313765, 49.2696342],
          [-81.2314452, 49.269226],
          [-81.2309342, 49.2689553],
          [-81.2303162, 49.2691155],
          [-81.2292328, 49.2687111],
          [-81.2303467, 49.2685928],
          [-81.2303849, 49.2682685],
          [-81.2275314, 49.2671737],
          [-81.2261886, 49.2669829],
          [-81.225197, 49.2670899],
          [-81.2249451, 49.2669829],
          [-81.2253342, 49.2667961],
          [-81.2250595, 49.266678],
          [-81.2235032, 49.2665634],
          [-81.222992, 49.266655],
          [-81.2230072, 49.2670553],
          [-81.2211914, 49.2667846],
          [-81.2202913, 49.267109],
          [-81.2200089, 49.2667046],
          [-81.2193908, 49.2665329],
          [-81.2185214, 49.2669716],
          [-81.217003, 49.2653846],
          [-81.2180024, 49.2655982],
          [-81.2179183, 49.2650795],
          [-81.2184831, 49.2649803],
          [-81.2178039, 49.2638932],
          [-81.2168656, 49.2635115],
          [-81.2154542, 49.2640304],
          [-81.2149125, 49.2637901],
          [-81.2147599, 49.2635269],
          [-81.2158355, 49.2633857],
          [-81.2144698, 49.2628366],
          [-81.2134782, 49.2621765],
          [-81.213173, 49.2617111],
          [-81.212555, 49.2615471],
          [-81.2125396, 49.261158],
          [-81.2117692, 49.261116],
          [-81.2113801, 49.2600555],
          [-81.2065583, 49.258091],
          [-81.2053147, 49.2581481],
          [-81.2047729, 49.2577476],
          [-81.2046356, 49.2575952],
          [-81.2052231, 49.2572059],
          [-81.2052077, 49.2569083],
          [-81.2032776, 49.2556076],
          [-81.202324, 49.2546082],
          [-81.2009202, 49.2505263],
          [-81.2009354, 49.2500724],
          [-81.2019195, 49.2488786],
          [-81.2021714, 49.246517],
          [-81.2016754, 49.2457007],
          [-81.2022476, 49.245739],
          [-81.2024384, 49.2454033],
          [-81.2003021, 49.243519],
          [-81.2000122, 49.2434807],
          [-81.1999893, 49.2439841],
          [-81.1996612, 49.2437784],
          [-81.1995164, 49.242714],
          [-81.1981277, 49.242714],
          [-81.1988144, 49.2421417],
          [-81.1979753, 49.2415352],
          [-81.1968918, 49.2411652],
          [-81.1962814, 49.2405586],
          [-81.1972428, 49.2405548],
          [-81.1954805, 49.2391701],
          [-81.1939698, 49.2372855],
          [-81.1934432, 49.235859],
          [-81.1928406, 49.2351875],
          [-81.1923447, 49.2350539],
          [-81.1926957, 49.2348137],
          [-81.192131, 49.2339859],
          [-81.1918031, 49.2338906],
          [-81.1903687, 49.2318115],
          [-81.1887514, 49.2303123],
          [-81.1882324, 49.2300988],
          [-81.1875688, 49.2290726],
          [-81.1857453, 49.2275658],
          [-81.1846618, 49.2271271],
          [-81.1843263, 49.2266083],
          [-81.1835328, 49.2261504],
          [-81.1827773, 49.2260055],
          [-81.1826248, 49.2261467],
          [-81.1828232, 49.2275085],
          [-81.1820907, 49.2267875],
          [-81.1808548, 49.2270698],
          [-81.1803666, 49.2273827],
          [-81.1803056, 49.2267875],
          [-81.1820221, 49.2264063],
          [-81.1812745, 49.2256583],
          [-81.1818162, 49.2257843],
          [-81.1824875, 49.2254563],
          [-81.18145, 49.2245025],
          [-81.1811525, 49.224266],
          [-81.1804505, 49.2243499],
          [-81.1807099, 49.2241135],
          [-81.1799544, 49.2235833],
          [-81.177475, 49.2224083],
          [-81.1764146, 49.2221756],
          [-81.1751786, 49.2209968],
          [-81.1748431, 49.2209778],
          [-81.1748505, 49.2213402],
          [-81.1742324, 49.2210922],
          [-81.1744232, 49.2207032],
          [-81.1735001, 49.2199478],
          [-81.1726303, 49.2196122],
          [-81.1722872, 49.2190132],
          [-81.1703187, 49.2173957],
          [-81.1682281, 49.2160186],
          [-81.1662445, 49.2155153],
          [-81.1657484, 49.2150039],
          [-81.1652678, 49.2150535],
          [-81.164856, 49.2147865],
          [-81.1629104, 49.2124252],
          [-81.1629257, 49.2116012],
          [-81.1622772, 49.2113761],
          [-81.1626053, 49.2107467],
          [-81.1620942, 49.2103233],
          [-81.1623689, 49.2096901],
          [-81.1614304, 49.2094422],
          [-81.1618041, 49.2092475],
          [-81.1613616, 49.2082557],
          [-81.1616303, 49.2069445],
          [-81.1612028, 49.2058009],
          [-81.160763, 49.2056215],
          [-81.1605853, 49.2058826],
          [-81.1595813, 49.2053173],
          [-81.1595394, 49.2051014],
          [-81.1580288, 49.2049237],
          [-81.1567092, 49.2044397],
          [-81.1551412, 49.2034613],
          [-81.1540003, 49.203013],
          [-81.1526784, 49.2017015],
          [-81.1521935, 49.2002629],
          [-81.1524758, 49.1983105],
          [-81.1511484, 49.1951549],
          [-81.1498569, 49.1946798],
          [-81.1480453, 49.1947721],
          [-81.1463698, 49.1943963],
          [-81.1454616, 49.1936689],
          [-81.1455015, 49.1931831],
          [-81.1458166, 49.1930208],
          [-81.1456645, 49.1926432],
          [-81.1448812, 49.1922844],
          [-81.1436042, 49.1921061],
          [-81.1424792, 49.1922602],
          [-81.1426582, 49.1924671],
          [-81.1422886, 49.1927554],
          [-81.1418904, 49.192729],
          [-81.1419036, 49.1925579],
          [-81.1413, 49.1926486],
          [-81.1405583, 49.1924877],
          [-81.1387005, 49.1906908],
          [-81.1387099, 49.1891438],
          [-81.1382816, 49.1881546],
          [-81.1382237, 49.1870752],
          [-81.1372881, 49.1861948],
          [-81.1351197, 49.1861524],
          [-81.1328959, 49.185858],
          [-81.1282955, 49.1846849],
          [-81.1264949, 49.1835445],
          [-81.1252685, 49.1815399],
          [-81.1249762, 49.1798759],
          [-81.1235858, 49.1780784],
          [-81.1235703, 49.1773497],
          [-81.1243645, 49.1767012],
          [-81.1285868, 49.1752934],
          [-81.1296281, 49.1746984],
          [-81.1299561, 49.1742124],
          [-81.1295673, 49.1724858],
          [-81.1298516, 49.1709743],
          [-81.128677, 49.1677819],
          [-81.1293039, 49.1661353],
          [-81.1298383, 49.1659458],
          [-81.1304835, 49.1661429],
          [-81.1324439, 49.16561],
          [-81.1357351, 49.1653003],
          [-81.1371848, 49.1637244],
          [-81.1369226, 49.163113],
          [-81.1349838, 49.1614152],
          [-81.1348136, 49.1593194],
          [-81.133575, 49.1577737],
          [-81.1336132, 49.1566942],
          [-81.1347712, 49.1538323],
          [-81.1343281, 49.1522676],
          [-81.1334753, 49.1512701],
          [-81.1333529, 49.1465746],
          [-81.1325655, 49.1443985],
          [-81.1339189, 49.1428858],
          [-81.1349921, 49.1391785],
          [-81.1357427, 49.1380082],
          [-81.135413, 49.1376577],
          [-81.1347958, 49.1376135],
          [-81.1327089, 49.1364736],
          [-81.1324058, 49.1359431],
          [-81.1315138, 49.1355933],
          [-81.1307856, 49.1350005],
          [-81.1297246, 49.1329057],
          [-81.1294575, 49.1303153],
          [-81.1289628, 49.129947],
          [-81.1288385, 49.1295244],
          [-81.1294248, 49.1283272],
          [-81.1302048, 49.1278226],
          [-81.1341071, 49.126226],
          [-81.134668, 49.1258116],
          [-81.1350355, 49.1249295],
          [-81.1349758, 49.1231396],
          [-81.1346178, 49.1225192],
          [-81.1339315, 49.1221513],
          [-81.1303119, 49.1216247],
          [-81.1290082, 49.1209875],
          [-81.1282667, 49.1203676],
          [-81.1273999, 49.1191182],
          [-81.1273137, 49.117544],
          [-81.128668, 49.1166069],
          [-81.1315707, 49.1156413],
          [-81.1343236, 49.1150804],
          [-81.1352425, 49.1153043],
          [-81.1360492, 49.1146736],
          [-81.1358455, 49.1153485],
          [-81.1365988, 49.1152306],
          [-81.1368028, 49.1146367],
          [-81.1363784, 49.1147901],
          [-81.1364177, 49.1141334],
          [-81.1369775, 49.1133951],
          [-81.1375797, 49.1131335],
          [-81.1379085, 49.1130971],
          [-81.1398292, 49.1139313],
          [-81.1399658, 49.1137332],
          [-81.1423225, 49.1136675],
          [-81.1434311, 49.1132072],
          [-81.1436755, 49.1124333],
          [-81.1432597, 49.1107788],
          [-81.1436258, 49.109456],
          [-81.1428665, 49.1074149],
          [-81.1430027, 49.1071809],
          [-81.1425908, 49.1068934],
          [-81.1418187, 49.1051494],
          [-81.1421429, 49.1035477],
          [-81.1425532, 49.1033224],
          [-81.1427955, 49.1017749],
          [-81.1413931, 49.1000225],
          [-81.1414592, 49.0992219],
          [-81.1420177, 49.0980966],
          [-81.142619, 49.0975472],
          [-81.1477652, 49.0960384],
          [-81.1493769, 49.0945072],
          [-81.1498569, 49.0946415],
          [-81.1505637, 49.0927965],
          [-81.151424, 49.0919857],
          [-81.1519119, 49.0903118],
          [-81.1519233, 49.0895472],
          [-81.1508505, 49.0880734],
          [-81.1510552, 49.0878123],
          [-81.1507775, 49.0865893],
          [-81.1513885, 49.0848162],
          [-81.1504134, 49.0838821],
          [-81.1486995, 49.0832637],
          [-81.1472882, 49.0829596],
          [-81.1468913, 49.083086],
          [-81.1455744, 49.082332],
          [-81.1455734, 49.0820262],
          [-81.1449836, 49.0816851],
          [-81.1452827, 49.0808751],
          [-81.1451165, 49.0802366],
          [-81.1458412, 49.079903],
          [-81.1460581, 49.0791199],
          [-81.1445361, 49.0785013],
          [-81.1419698, 49.0765254],
          [-81.1421588, 49.0755717],
          [-81.1428132, 49.0745543],
          [-81.1422646, 49.0742311],
          [-81.1419333, 49.0733051],
          [-81.1422409, 49.0707499],
          [-81.1430293, 49.0687789],
          [-81.1428335, 49.0673309],
          [-81.1431718, 49.0659092],
          [-81.1428423, 49.0656038],
          [-81.142303, 49.0637243],
          [-81.1401885, 49.0614331],
          [-81.138885, 49.0603731],
          [-81.138442, 49.058459],
          [-81.0743542, 49.0583797],
          [-81.0743901, 48.9717821],
          [-81.0085728, 48.9714256],
          [-80.9421604, 48.9715997],
          [-80.94226, 49.0148744],
          [-80.94199, 49.0588184],
          [-80.8761982, 49.0588256],
          [-80.8766015, 49.2000644],
          [-80.9843016, 49.1999669],
          [-80.9911327, 49.2016942],
          [-80.992931, 49.2026749],
          [-80.9940702, 49.204258],
          [-80.9939877, 49.2058052],
          [-80.995278, 49.2087738],
          [-80.9958681, 49.2094754],
          [-80.9967194, 49.2099072],
          [-80.9975569, 49.2100601],
          [-80.9987101, 49.2098713],
          [-81.001003, 49.2086119],
          [-81.0024584, 49.2081891],
          [-81.0069205, 49.2088906],
          [-81.0104629, 49.2091781],
          [-81.0117671, 49.2090791],
          [-81.0140597, 49.2082063],
          [-81.0153089, 49.2082061],
          [-81.0167512, 49.209969],
          [-81.0173835, 49.2121459],
          [-81.0183312, 49.2129552],
          [-81.0203774, 49.2139623],
          [-81.021009, 49.2135484],
          [-81.0219976, 49.2132874],
          [-81.0243456, 49.2137368],
          [-81.0263779, 49.2138892],
          [-81.0269, 49.2144467],
          [-81.026763, 49.2151214],
          [-81.0264061, 49.2153733],
          [-81.0266955, 49.2173072],
          [-81.0360486, 49.2194906],
          [-81.0373403, 49.2206146],
          [-81.038248, 49.2224764],
          [-81.0385373, 49.2235378],
          [-81.0382635, 49.2247162],
          [-81.0392254, 49.2254266],
          [-81.040517, 49.2259657],
          [-81.0445967, 49.2267918],
          [-81.044886, 49.2276553],
          [-81.043857, 49.2291669],
          [-81.0439813, 49.2298055],
          [-81.0450398, 49.2307766],
          [-81.0474581, 49.2314863],
          [-81.0484887, 49.2320076],
          [-81.0502624, 49.2336979],
          [-81.0519727, 49.2404616],
          [-81.052853, 49.2413877],
          [-81.0527712, 49.2419996],
          [-81.0518786, 49.2423956],
          [-81.0523057, 49.24352],
          [-81.0536396, 49.2445627],
          [-81.0554673, 49.2448676],
          [-81.0556322, 49.2450025],
          [-81.0550417, 49.2452367],
          [-81.0564164, 49.2458927],
          [-81.057406, 49.246198],
          [-81.057914, 49.2459189],
          [-81.0580931, 49.2463056],
          [-81.0603469, 49.2467183],
          [-81.0626301, 49.2485071],
          [-81.0627416, 49.2498203],
          [-81.0612465, 49.2519261],
          [-81.0609454, 49.2528797],
          [-81.0615513, 49.2539228],
          [-81.062638, 49.2548218],
          [-81.0637397, 49.2565573],
          [-81.0643622, 49.2597053],
          [-81.0641484, 49.2644099],
          [-81.0626764, 49.2742784],
          [-81.0630756, 49.2746921],
          [-81.0647667, 49.274763],
          [-81.0644371, 49.2750511],
          [-81.0627049, 49.2750521],
          [-81.062376, 49.275754],
          [-81.062666, 49.2877354],
          [-81.0635482, 49.289381],
          [-81.0652008, 49.2911701],
          [-81.066441, 49.2930493],
          [-81.0681527, 49.2975459],
          [-81.0710326, 49.3012322],
          [-81.0741237, 49.3046518],
          [-81.0740994, 49.3192999],
          [-81.1304372, 49.3191749],
          [-81.1415163, 49.3193632],
          [-81.2639035, 49.3189978],
          [-81.2634431, 49.317402],
          [-81.2621157, 49.3167495],
          [-81.26165, 49.3162842],
          [-81.2616196, 49.3157615],
          [-81.2621536, 49.3150177],
          [-81.2619171, 49.3149489],
          [-81.2612839, 49.3154067],
          [-81.2605515, 49.3167114],
          [-81.2607195, 49.3153342],
          [-81.260071, 49.3146401],
          [-81.2610627, 49.3148841],
          [-81.2619171, 49.3131869],
          [-81.2649917, 49.3107946],
          [-81.2655183, 49.3094138],
          [-81.2655487, 49.3080023],
          [-81.266594, 49.3063811],
          [-81.2668305, 49.3049736],
        ],
      ],
    ],
    lat: 49.1733,
    long: -81.0678,
  },
  {
    name: "Windsor",
    issue: "Not found on Property.ca",
    coordinates: [
      [
        [
          [-83.1148496, 42.255921001],
          [-83.0765905, 42.246311601],
          [-83.0762225, 42.252598901],
          [-83.0708093, 42.249884901],
          [-83.0711932, 42.250203701],
          [-83.070319, 42.255397401],
          [-83.0598228, 42.252720201],
          [-83.0391906, 42.252116701],
          [-83.0392003, 42.252336701],
          [-83.0351692, 42.252257901],
          [-83.0336705, 42.250000001],
          [-83.0324554, 42.249140101],
          [-83.023767, 42.245824401],
          [-82.9981075, 42.236334401],
          [-82.9970933, 42.236140301],
          [-82.9971009, 42.235951101],
          [-82.9904177, 42.234419301],
          [-82.9880219, 42.234523701],
          [-82.9867859, 42.234158101],
          [-82.970543, 42.244722901],
          [-82.9688032, 42.245356201],
          [-82.960945, 42.247337001],
          [-82.9612579, 42.251924601],
          [-82.959091, 42.249219401],
          [-82.9567796, 42.249407601],
          [-82.9554979, 42.249181701],
          [-82.9534758, 42.248311101],
          [-82.935509, 42.242879101],
          [-82.9057536, 42.242037601],
          [-82.903587, 42.272339201],
          [-82.9037993, 42.272346901],
          [-82.9048676, 42.274528301],
          [-82.8977789, 42.275779101],
          [-82.8979984, 42.276379201],
          [-82.8976645, 42.276441601],
          [-82.90043, 42.288587201],
          [-82.8958852, 42.288494901],
          [-82.8980067, 42.297208401],
          [-82.8977358, 42.298607901],
          [-82.8962555, 42.302311201],
          [-82.8950502, 42.303542301],
          [-82.895279, 42.304583101],
          [-82.8956775, 42.304524601],
          [-82.897872, 42.313478501],
          [-82.8911364, 42.312788001],
          [-82.901841, 42.356791301],
          [-82.945376, 42.346943701],
          [-82.9591464, 42.339437301],
          [-82.9887708, 42.332447701],
          [-83.0190202, 42.329388601],
          [-83.06341, 42.317804701],
          [-83.0794903, 42.308714201],
          [-83.0967741, 42.289975301],
          [-83.1116269, 42.260141401],
          [-83.1148496, 42.255921001],
        ],
      ],
    ],
    lat: 42.3149,
    long: -83.0364,
  },
];

export default CityBoundariesCordinatesData;
