import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";


export default function AccordionPanel({
  selectedStatusValues,
  setSelectedStatusValues,
  setUrlParams,
}) {


  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : "");
  };

  const getIcon = (panel) => {
    return expanded === panel ? <RemoveIcon /> : <AddIcon />;
  };

  const handleStatusRadioChange = (event) => {
    setSelectedStatusValues(event.target.value);
    setUrlParams("status",event.target.value);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          expandIcon={getIcon("panel5")}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography sx={{ fontFamily: "Montserrat" ,fontWeight:'bold',paddingLeft:'12px'}}>Status</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RadioGroup
            aria-label="status"
            name="status"
            value={selectedStatusValues}
            onChange={handleStatusRadioChange}
          >
            <FormControlLabel
              value="Available"
              control={<Radio />}
              label={<span className="font">Available</span>}
            />
            <FormControlLabel
              value="On-Hold"
              control={<Radio />}
              label={<span className="font">On-Hold</span>}
            />
            <FormControlLabel
              value="Unavailable"
              control={<Radio />}
              label={<span className="font">Unavailable</span>}
            />
            <FormControlLabel
              value="Sold"
              control={<Radio />}
              label={<span className="font">Sold</span>}
            />
          </RadioGroup>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
