import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentDetails from "./PaymentDetails";
import baseURL from "../../../config";
import axios from "axios";
import Cookies from "universal-cookie";

const PayModal = ({
  assignmentId,
  setShowPayModal,
  selectedPackages,
  setSelectedPackages,
  showPayModal,
  handleClosePayModal,
  handlePayLater,
  showPayLaterBtn,
  setData,
  data,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const cookies = new Cookies();
  const jwt_token = cookies.get("jwt_authorization");
  const [stripePromise, setStripePromise] = useState(null);

  const totalAmount = selectedPackages
    ?.reduce((total, pkg) => total + pkg.price, 0)
    ?.toFixed(0);

  useEffect(() => {
    try{
      const stripeLoad = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
      setStripePromise(stripeLoad);
      
    }catch(error){
      console.log(error)
    }
  }, []);

  const fetchClientSecret = async () => {
    const formData = {
      Amount: totalAmount,
      Currency: "usd",
    };

    try {
      const response = await axios.post(
        `${baseURL}/api/stripe/CreatePaymentIntent`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.data?.result === 1) {
        setClientSecret(response?.data?.data);
      }
    } catch (error) {
      console.error("Error making the API call:", error);
      setShowPayModal(false);
    }
  };

  useEffect(() => {
    fetchClientSecret();
    // eslint-disable-next-line
  }, []);

  const options = {
    clientSecret: clientSecret,
  };
  return (
    <Modal
      show={showPayModal}
      centered
      onHide={handleClosePayModal}
      className="BumpAdd_modal"
      backdrop="static"
    >
      <Modal.Body className="Bump_modal_body">
        {clientSecret && (
          <Elements stripe={stripePromise} options={options}>
            <PaymentDetails
              showPayLaterBtn={showPayLaterBtn}
              setShowPayModal={setShowPayModal}
              selectedPackages={selectedPackages}
              setSelectedPackages={setSelectedPackages}
              handlePayLater={handlePayLater}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              assignmentId={assignmentId}
              data={data}
              setData={setData}
            />
          </Elements>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PayModal;
