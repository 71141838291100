import React, { useEffect, useState } from "react";

export default function BathroomButtons({
  selectedBathButtons,
  handleBathSelectionChange,
}) {
  const [activeBathButtons, setActiveBathButtons] = useState([]);

  useEffect(() => {
    // Set the active buttons based on the selected buttons prop
    setActiveBathButtons(selectedBathButtons);
  }, [selectedBathButtons]);

  const handleClick = (button) => {
    const isActive = activeBathButtons.includes(button);

    let newActiveBathButtons;
    if (isActive) {
      // If the button is active, remove it from the active buttons state
      newActiveBathButtons = activeBathButtons.filter((btn) => btn !== button);
    } else {
      // If the button is inactive, add it to the active buttons state
      newActiveBathButtons = [...activeBathButtons, button];
    }

    setActiveBathButtons(newActiveBathButtons);
    handleBathSelectionChange(newActiveBathButtons); // Pass the updated values to the parent
  };

  return (
    <div>
      <button
        className={`btn ${
          activeBathButtons.includes("1")
            ? "btn p-0 font btnbeds2"
            : "btn p-0 font btnbeds"
        }`}
        onClick={() => handleClick("1")}
      >
        1
      </button>
      <button
        className={`btn ${
          activeBathButtons.includes("1.5")
            ? "btn p-0 font btnbeds2"
            : "btn p-0 font btnbeds"
        }`}
        onClick={() => handleClick("1.5")}
      >
        1.5
      </button>
      <button
        className={`btn ${
          activeBathButtons.includes("2")
            ? "btn p-0 font btnbeds2"
            : "btn p-0 font btnbeds"
        }`}
        onClick={() => handleClick("2")}
      >
        2
      </button>
      <button
        className={`btn ${
          activeBathButtons.includes("2.5")
            ? "btn p-0 font btnbeds2"
            : "btn p-0 font btnbeds"
        }`}
        onClick={() => handleClick("2.5")}
      >
        2.5
      </button>
      <button
        className={`btn ${
          activeBathButtons.includes("3")
            ? "btn p-0 font btnbeds2"
            : "btn p-0 font btnbeds"
        }`}
        onClick={() => handleClick("3")}
      >
        3
      </button>
      <button
        className={`btn ${
          activeBathButtons.includes("3.5")
            ? "btn p-0 font btnbeds2"
            : "btn p-0 font btnbeds"
        }`}
        onClick={() => handleClick("3.5")}
      >
        3.5
      </button>
      <button
        className={`btn ${
          activeBathButtons.includes("4")
            ? "btn p-0 font btnbeds2"
            : "btn p-0 font btnbeds"
        }`}
        onClick={() => handleClick("4")}
      >
        4
      </button>
    </div>
  );
}
