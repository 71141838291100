import React, { useState, memo } from "react";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
// import Schools from "./Schools";
// import Hospitals from "./Hospitals";
// import Grocery from "./Grocery";
// import BarsAndResturant from "./BarsAndResturant";
// import Transists from "./Transists";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { ExplorePropertyIcon } from "../../../../SvgIcons/Icons";
import PropertyMap from "./PropertyMap";
import SimpleMapCard from "./SimpleMapCard";

const ExploreProperty = ({ isMobileView, data }) => {
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  };

  const tabLabels = [
    "Schools",
    "Hospitals",
    "Grocery",
    "Restaurants",
    "Bars",
    "Transists",
  ];

  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = useState(true);
  const [activeTabForMap, setActiveTabForMap] = useState("school");
  const [nearPlacesData, setNearPlacesData] = useState([]);
  const [hoveredCardId, setHoveredCardId] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const handleChange = () => {
    if (isMobileView) {
      setExpanded(!expanded);
    }
  };

  const handleTabChange = (event, newValue) => {
    if (isLoading) {
      return;
    }

    setValue(newValue);
    setIsLoading(true);

    switch (newValue) {
      case 0:
        setActiveTabForMap("school");
        break;
      case 1:
        setActiveTabForMap("hospital");
        break;
      case 2:
        setActiveTabForMap("grocery");
        break;
      case 3:
        setActiveTabForMap("restaurant");
        break;
      case 4:
        setActiveTabForMap("bar");
        break;
      case 5:
        setActiveTabForMap("bus_station");
        break;
      default:
        setActiveTabForMap("school");
    }
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className="Property_details_Web_Accordion Property_simpleAccordion PropertyDetails_MobileAccordion"
      sx={{ background: "#fff" }}
    >
      <AccordionSummary
        expandIcon={
          isMobileView &&
          (expanded ? (
            <RemoveIcon className="Property_minusIcon" />
          ) : (
            <AddIcon className="Property_plusIcon" />
          ))
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={isMobileView ? "d-flex align-items-center gap-2" : ""}>
          {isMobileView && <ExplorePropertyIcon />}
          <h2 className="property_Details_heading">
            Explore {data?.projectName}
          </h2>
          {!isMobileView && (
            <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
              "Discover the vibrant neighborhood, with easy access to transit,
              top-rated schools, popular restaurants, and nearby hospitals—all
              just steps from your future home."
            </p>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {isMobileView && (
          <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start mt-1">
            "Discover the vibrant neighborhood, with easy access to transit,
            top-rated schools, popular restaurants, and nearby hospitals—all
            just steps from your future home."
          </p>
        )}

        <PropertyMap
          data={data}
          setNearPlacesData={setNearPlacesData}
          setIsLoading={setIsLoading}
          activeTab={activeTabForMap}
          hoveredCardId={hoveredCardId}
        />

        <Box className="mt-3">
          <AppBar
            position="static"
            sx={{
              boxShadow: "none",
            }}
          >
            <Tabs
              value={value}
              onChange={handleTabChange}
              textColor="inherit"
              sx={{
                backgroundColor: "#fff",
              }}
              className="Property_details_tabs"
              elevation={0}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              {tabLabels?.map((tab, index) => (
                <Tab
                  key={index}
                  label={`${tab}`}
                  sx={{
                    border: "1px solid #CCCCCC",
                    fontWeight: "700",
                    padding: "15px 30px",
                  }}
                />
              ))}
            </Tabs>
          </AppBar>
          {tabLabels.map((_, index) => (
            <TabPanel
              key={index}
              hidden={value !== index}
              className="PropertyDetail_tabPanel"
            >
              <SimpleMapCard
                data={nearPlacesData}
                setHoveredCardId={setHoveredCardId}
                showMore={showMore}
                setShowMore={setShowMore}
              />
            </TabPanel>
          ))}
          {/* <TabPanel value={value} index={0} className="PropertyDetail_tabPanel">
            <SimpleMapCard
              data={nearPlacesData}
              setHoveredCardId={setHoveredCardId}
              showMore={showMore}
              setShowMore={setShowMore}
            />
          </TabPanel>
          <TabPanel value={value} index={1} className="PropertyDetail_tabPanel">
            <SimpleMapCard
              data={nearPlacesData}
              setHoveredCardId={setHoveredCardId}
              showMore={showMore}
              setShowMore={setShowMore}
            />
          </TabPanel>
          <TabPanel value={value} index={2} className="PropertyDetail_tabPanel">
            <SimpleMapCard
              data={nearPlacesData}
              setHoveredCardId={setHoveredCardId}
              showMore={showMore}
              setShowMore={setShowMore}
            />
          </TabPanel>
          <TabPanel value={value} index={3} className="PropertyDetail_tabPanel">
            <SimpleMapCard
              setHoveredCardId={setHoveredCardId}
              data={nearPlacesData}
            />
          </TabPanel>
          <TabPanel value={value} index={4} className="PropertyDetail_tabPanel">
            <SimpleMapCard
              data={nearPlacesData}
              setHoveredCardId={setHoveredCardId}
              showMore={showMore}
              setShowMore={setShowMore}
            />
          </TabPanel>
          <TabPanel value={value} index={5} className="PropertyDetail_tabPanel">
            <SimpleMapCard
              data={nearPlacesData}
              setHoveredCardId={setHoveredCardId}
              showMore={showMore}
              setShowMore={setShowMore}
            />
          </TabPanel> */}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(ExploreProperty);
