import React, { useState, memo, useEffect } from "react";
import { AddNoteIcon, EditIcon } from "../../../../SvgIcons/Icons";
import ReviewsRating from "./RatingReviews";
import RatingValueDialog from "../Modals/RatingValueDialog";
import { useDispatch, useSelector } from "react-redux";
import { RotatingLines } from "react-loader-spinner";
import { toast } from "react-toastify";
import axios from "axios";
import baseURL from "../../../../config";
import { updateRatingData } from "../../../../ReduxStore/Slices/RatingDataSlice";

const PropertyNotes = ({
  data,
  isModal,
  onClose,
  setIsSignUpModalOpen,
  isUserlogin,
  setSignUpModalHeading,
}) => {
  const notesData = useSelector((state) => state.RatingDataSlice?.ratingData);
  const dispatch = useDispatch();

  const [isNoteDown, setIsNoteDown] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [contentValue, setContentValue] = useState("");
  const [contentError, setContentError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [index, setIndex] = useState("");

  const ratingData = notesData?.filter(
    (item) => item.content !== "___description"
  );

  const contentDescriptionObject = notesData?.find(
    (item) => item.content === "___description"
  );

  useEffect(() => {
    setContentValue(contentDescriptionObject?.values);
  }, [contentDescriptionObject]);

  const handleCancelBtn = () => {
    setContentError(false);
    if (isModal) {
      onClose();
    } else {
      setIsNoteDown(false);
    }
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleNoteDown = () => {
    if (isUserlogin) {
      setIsNoteDown(true);
    } else {
      setIsSignUpModalOpen(true);
      setSignUpModalHeading(
        "Become a member to access & Write Research Notes."
      );
    }
  };

  const handleEditClick = (i) => {
    if (isUserlogin) {
      setIsDialogOpen(true);
      setIndex(i);
    } else {
      setIsSignUpModalOpen(true);
      setSignUpModalHeading(
        "Become a member to access & Write Research Notes."
      );
    }
  };

  const handleNoteSubmit = async () => {
    let error = false;
    if ((contentValue || "").trim().length === 0) {
      setContentError(true);
      error = true;
    }

    if (!error && isUserlogin) {
      setIsLoading(true);
      const jwt_token = isUserlogin;
      const headers = {
        Authorization: `Bearer ${jwt_token}`,
      };
      const formData = {
        Id: contentDescriptionObject ? contentDescriptionObject.id : 0,
        ProId: data?.uId,
        UserId: "1",
        Category: "Assignment",
        Content: "___description",
        Values: contentValue,
      };
      try {
        const response = await axios.post(
          `${baseURL}/api/AuthData/AddUpdateRateProperty`,
          formData,
          {
            headers,
          }
        );
        if (response?.data?.result === 1) {
          toast.success("Value saved Successfully");
          dispatch(
            updateRatingData({
              id: response?.data?.data,
              content: "___description",
              values: contentValue,
            })
          );
          setIsLoading(false);
        } else {
          toast.error("Something went wrong");
          setIsLoading(false);
        }
      } catch (error) {
        toast.error(error.message);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="prop_represent_linkStatic">
        <div
          className={`Prop_details_section_box Propert_detailRepresentatorCard Prop_detailsRatingCard mt-3 ${
            isModal && "RatingMobile_modal"
          }`}
        >
          {isModal && (
            <h5 className="Research_noteHeading mb-2">Research Note</h5>
          )}
          <div className="d-flex justify-content-between">
            <p className="m-0 d-flex align-items-center">
              <span className="pe-1">
                {ratingData[0]?.content ? ratingData[0]?.content : "Price"}
              </span>{" "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => handleEditClick(0)}
              >
                <EditIcon />
              </span>{" "}
            </p>
            <ReviewsRating
              isReadOnly={true}
              value={ratingData[0]?.values ? ratingData[0]?.values : 0}
            />
          </div>
          <div className="mt-2 d-flex justify-content-between">
            <p className="m-0 d-flex align-items-center">
              <span className="pe-1">
                {ratingData[1]?.content
                  ? ratingData[1]?.content
                  : "Floor Plans"}
              </span>{" "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => handleEditClick(1)}
              >
                <EditIcon />
              </span>{" "}
            </p>
            <ReviewsRating
              isReadOnly={true}
              value={ratingData[1]?.values ? ratingData[1]?.values : 0}
            />
          </div>
          <div className="mt-2 d-flex justify-content-between">
            <p className="m-0 d-flex align-items-center">
              <span className="pe-1" style={{ letterSpacing: ".20px" }}>
                {ratingData[2]?.content
                  ? ratingData[2]?.content
                  : "Estimated Completed time"}
              </span>{" "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => handleEditClick(2)}
              >
                <EditIcon />
              </span>{" "}
            </p>
            <ReviewsRating
              isReadOnly={true}
              value={ratingData[2]?.values ? ratingData[2]?.values : 0}
            />
          </div>

          {!(isNoteDown || isModal) && (
            <button
              className="mt-3 propertyDetails_moreInfo fw-600 DetailsLight_bgBtn d-flex justify-content-between align-items-center gap-2"
              onClick={handleNoteDown}
            >
              <span>Research note for this property</span>
              <AddNoteIcon color={"#007BFF"} />
            </button>
          )}

          {(isNoteDown || isModal) && (
            <div className="PropertyDetails_notes mt-3">
              <textarea
                placeholder="Jot down your research, observations, and findings related to the properties here."
                value={contentValue}
                onChange={(e) => {
                  setContentValue(e.target.value.trimStart());
                  setContentError(false);
                }}
              ></textarea>
              {contentError ? (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginBottom: "10px",
                  }}
                >
                  This field is Required!
                </p>
              ) : null}
              <div className="d-flex align-items-center gap-2 mt-1">
                <button
                  className="propertyDetails_moreInfo w-50 PropCancelNotes_btn"
                  onClick={handleCancelBtn}
                >
                  Cancel
                </button>
                {isLoading ? (
                  <div
                    className="d-flex w-50 justify-content-center"
                    style={{ width: "calc(50% - 4px)" }}
                  >
                    <RotatingLines width={30} strokeColor="grey" />
                  </div>
                ) : (
                  <button
                    className="propertyDetails_moreInfo w-50"
                    onClick={handleNoteSubmit}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
        {isDialogOpen && (
          <RatingValueDialog
            index={index}
            open={isDialogOpen}
            handleClose={handleDialogClose}
            isUserlogin={isUserlogin}
            data={data}
          />
        )}
      </div>
    </>
  );
};

export default memo(PropertyNotes);
